<div class="popup-mini-cart" [class.showcart]="showModal">
    <div class="cart-inner">
        <div class="cart_top">
            <h3><i class="fa fa-shopping-cart" aria-hidden="true"></i> My Shopping Cart</h3>
            <div class="close-cart" (click)="closeCart()">
                <a href="javascript:void(0)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <div class="cart-content" *ngIf="products.length > 0">
            <div class="cart-item" *ngFor="let product of products">
                <div class="item-image">
                    <a [routerLink]="['/shop/', product.handle]">
                        <img [src]="product.image" [alt]="product.name">
                    </a>
                </div>
                <div class="item-content">
                    <div class="close-circle" (click)="removeItem(product.cart_item_id)" *ngIf="canbeChng">
                        <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                    <div class="item-name-variant">
                        <a [routerLink]="['/p', product.handle]" class="prod-name">
                            {{ product.name | titlecase }}                               
                        </a>
                        <ng-container *ngFor="let option of product.option">
                            <small>{{option.name}}: {{option.value}}</small>
                        </ng-container>
                    </div>
                    <div class="item-qty-price" *ngIf="canbeChng">
                        <div class="qty-box">
                            <div class="input-group">
                                <span class="input-group-prepend">
                                    <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement(product.cart_item_id)">
                                        <i class="fa fa-minus"></i>
                                    </button>
                                </span>
                                <input type="text" name="quantity" class="form-control input-number" disabled [(ngModel)]="product.quantity" />
                                <span class="input-group-prepend">
                                    <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment(product.cart_item_id)">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div class="item-price">
                            <span class="price">
                            {{ product.total }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cart-bottom" *ngIf="products.length > 0">
            
                    

            <div class="coupon">
                <h5>** Apply coupon and get discount.</h5>
                <app-coupon></app-coupon>
            </div>
              
            <div class="total">
                <span>Subtotal:</span> <span>{{ cartTotal }}</span>
            </div>
            <div class="buttons">
                <a (click)="gotoCart()" class="btn btn-solid view-cart">
                    <i class="fa fa-cart-plus"></i> view cart</a>
                <a (click)="gotoCheckout()" class="btn btn-solid checkout" *ngIf="showChkoutBtn">checkout</a>
            </div>
        </div>
        <div class="empty-content" *ngIf="products.length == 0">
            <p>Your cart is empty</p>
            <a [routerLink]="'/shop'" class="btn btn-solid">Continue Shopping</a>
        </div>
    </div>
</div>
<div class="mini-cart-overlay"  [class.show]="showModal" (click)="closeCart()"></div>