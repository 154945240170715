import { Injectable, Inject } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class HttpConfigInterceptor implements HttpInterceptor {
    private httpProtocol: string = '';
    private routerLocation: any = '';

	constructor(
        private router: Router
    ) {
        this.routerLocation = this.router['location']._platformLocation.location;
        if (this.routerLocation) {
            this.httpProtocol = this.routerLocation.protocol;
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let apitoken = sessionStorage.getItem('api_token');
        let reqUrl = request.url;
        if (this.httpProtocol == 'https:') {
            reqUrl = reqUrl.replace('http://', 'https://');
            request = request.clone({ url: reqUrl });
        }
        if (apitoken && reqUrl.indexOf('assets/') <= 0 && reqUrl.indexOf('common/apilogin') <= 0 && reqUrl.indexOf('api_token') <= 0) {
            reqUrl = reqUrl + '&api_token=' + apitoken;
            request = request.clone({ url: reqUrl });
        }
        /*if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
        if (!request.headers.has('Accept')) {
            request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        }*/

        return next.handle(request).pipe(
        	retry(1),
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                /*let data = {};
                data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };*/
                return throwError(error);
            })
        );
    }
}