// Home Slider
export let HomeSlider: any = {
  loop: true,
  nav: true,
  dots: false,
  margin: 15,
  autoplay: true,
  navContainerClass: "owl-nav",
  navClass: ["owl-prev", "owl-next"],
  //navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
  navText: [
    '<img src="assets/images/owl-prev.png">',
    '<img src="assets/images/owl-next.png">',
  ],
  responsive: {
    0: {
      items: 1,
      nav: false,
      dots: true,
    },
    400: {
      items: 1,
    },
    740: {
      items: 1,
    },
    940: {
      items: 1,
    },
  },
};

// Blog Slider
export let BlogSlider: any = {
  loop: true,
  dots: false,
  navSpeed: 300,
  margin: 15,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    740: {
      items: 2,
    },
    940: {
      items: 3,
    },
    1200: {
      items: 3,
    },
  },
};

// Certificates Slider
export let CertificateSlider: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  nav: true,
  margin: 15,
  navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
  autoWidth: false,
  responsive: {
    991: {
      items: 4,
    },
    767: {
      items: 3,
    },
    0: {
      items: 1,
    },
  },
};

// Testimonial Slider
export let TestimonialSlider: any = {
  loop: true,
  dots: false,
  navSpeed: 300,
  nav: true,
  margin: 45,
  //navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
  navText: [
    '<img src="assets/images/owl-prev.png">',
    '<img src="assets/images/owl-next.png">',
  ],
  autoWidth: false,
  responsive: {
    991: {
      items: 3,
    },
    767: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};

// Testimonial Slider
export let ReviewSlider: any = {
  loop: true,
  dots: false,
  navSpeed: 300,
  nav: true,
  margin: 45,
  //navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
  navText: [
    '<img src="assets/images/owl-prev.png">',
    '<img src="assets/images/owl-next.png">',
  ],
  autoWidth: false,
  responsive: {
    991: {
      items: 3,
    },
    767: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};

// Compare Slider
export let CompareSlider: any = {
  loop: true,
  dots: false,
  navSpeed: 300,
  margin: 15,
  responsive: {
    991: {
      items: 4,
    },
    767: {
      items: 3,
    },
    586: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};
//benefitsSlider
export let benefitsSlider: any = {
  loop: true,
  dots: false,
  navSpeed: 300,
  margin: 45,
  nav: true,
  navText: [
    '<img src="assets/images/owl-prev.png">',
    '<img src="assets/images/owl-next.png">',
  ],
  autoWidth: false,
  responsive: {
    991: {
      items: 4,
    },
    767: {
      items: 3,
    },
    420: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};
// Product Slider
export let ProductSlider: any = {
  loop: true,
  dots: false,
  navSpeed: 300,
  margin: 40,
  nav: true,
  //navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
  navText: [
    '<img src="assets/images/owl-prev.png">',
    '<img src="assets/images/owl-next.png">',
  ],
  autoWidth: false,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
  // responsive: {
  //   991: {
  //     items: 3,
  //   },
  //   767: {
  //     items: 3,
  //   },
  //   420: {
  //     items: 3,
  //   },
  //   0: {
  //     items: 1,
  //   },
  // },
};
//BestsellersSlider
export let BestsellersSlider: any = {
  loop: true,
  dots: false,
  navSpeed: 300,
  margin: 45,
  nav: true,
  navText: [
    '<img src="assets/images/owl-prev.png">',
    '<img src="assets/images/owl-next.png">',
  ],
  autoWidth: false,
  responsive: {
    991: {
      items: 3,
    },
    767: {
      items: 3,
    },
    420: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};
// box Slider
export let BoxSlider: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  margin: 15,
  nav: true,
  navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
  autoWidth: false,
  responsive: {
    991: {
      items: 4,
    },
    767: {
      items: 3,
    },
    420: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};

// Ingredients Slider
export let IngredientsSlider: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  margin: 15,
  nav: true,
  navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
  autoWidth: false,
  responsive: {
    991: {
      items: 4,
    },
    767: {
      items: 3,
    },
    420: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};

// Product Slider
export let ProductOneSlider: any = {
  items: 1,
  loop: true,
  dots: false,
  navSpeed: 300,
  margin: 15,
};

// New Product Slider
export let NewProductSlider: any = {
  items: 1,
  loop: true,
  nav: true,
  dots: false,
  margin: 15,
  navContainerClass: "owl-nav",
  navClass: ["owl-prev", "owl-next"],
  navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
};

// Product Details Main Slider
export let ProductDetailsMainSlider: any = {
  items: 1,
  nav: false,
  dots: false,
  autoplay: false,
  slideSpeed: 150,
  loop: true,
  autoWidth: true,
  navText: ["", ""],
};

// Product Details Thumb Slider
export let ProductDetailsThumbSlider: any = {
  items: 5,
  loop: false,
  margin: 10,
  nav: true,
  dots: false,
  autoWidth: false,
  navText: ["", ""],
};

// Product Quickview Main Slider
export let qvMainSlider: any = {
  items: 1,
  nav: false,
  dots: false,
  autoplay: false,
  slideSpeed: 150,
  loop: true,
  autoWidth: true,
  navText: ["", ""],
};

// Product Quickview Thumb Slider
export let qvThumbSlider: any = {
  items: 4,
  loop: false,
  margin: 10,
  nav: true,
  dots: false,
  autoWidth: true,
  navText: ["", ""],
};
