import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { isObject } from 'rxjs-compat/util/isObject';

@Pipe({
  	name: 'formatPrice'
})

export class FormatPricePipe extends CurrencyPipe implements PipeTransform {
	// symbols are 'code' | 'symbol' | 'symbol-narrow'
	transform(value: number = 0, currency: any, showSymbol: string | boolean = 'symbol', digits: string = '', locale: string = 'en'): any {
    	let currencyCode: string = 'INR';
    	if (isObject(currency)) {
    		value *= currency.value;
    		currencyCode = currency.code;
    		if (!digits)
    			digits = '0.'+currency.decimal+'-'+currency.decimal;
    		showSymbol = currency.symbol;
    	}

    	return super.transform(value, currencyCode, showSymbol, digits, locale);
  	}
}
