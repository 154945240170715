<ng-template #videoModal let-modal>
    <div class="modal-content">
    	<button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
            <iframe [src]="link | safeHtml:'resourceUrl'" allowfullscreen allow="autoplay"></iframe>
        </div>
    </div>
</ng-template>
