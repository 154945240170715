import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Product } from "../../../classes/product";
import { qvMainSlider, qvThumbSlider } from '../../../data/slider';
import { CartService } from '../../../services/cart.service';
import { ProductService } from '../../../services/product.service';

@Component({
    selector: 'app-quick-view',
    templateUrl: './quick-view.component.html',
    styleUrls: ['./quick-view.component.scss']
})

export class QuickViewComponent implements OnInit, OnDestroy {

    @Input() product: Product;
    @ViewChild("quickView", {
        static: false
    }) QuickView: TemplateRef < any > ;

    public owlMainSliderConfig: any = qvMainSlider;
    public owlThumbConfig: any = qvThumbSlider;
    public closeResult: string;
    public counter: number = 1;
    public modalOpen: boolean = false;
    public activeSlide: any = 0;
    public addToCartForm: FormGroup;
    public formSubmitted: boolean;
    public isDisableAtc: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private router: Router,
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private productService: ProductService,
        private cartService: CartService
    ) {}

    ngOnInit(): void {
        this.addToCartForm = this.formBuilder.group({
            product_id: [this.product.product_id, [Validators.required, Validators.pattern(/\d/)]],
            quantity: [{value: this.counter, disabled: true}, [Validators.required, Validators.min(1)]],
        });

        if (this.product.options) {
            let optionfb = <FormGroup>this.formBuilder.group({});
            this.product.options.forEach(option => {
                let validator = [];
                if (option.required) {
                    validator.push(Validators.required);
                }
                optionfb.addControl(option.product_option_id, this.formBuilder.control('', validator));                 
            });
            this.addToCartForm.addControl('prodoption', optionfb);
        }
    }

    get optionControls() {
        return (this.addToCartForm.get('prodoption') as FormArray).controls;
    }

    isFieldValid(field: string) {
        return (
          (!this.addToCartForm.get(field).valid && this.addToCartForm.get(field).touched) ||
          (this.addToCartForm.get(field).untouched && !this.addToCartForm.get(field).valid && this.formSubmitted)
        );
    }

    // Increament
    increment() {
        this.counter++;
        this.addToCartForm.controls['quantity'].setValue(this.counter);
    }

    // Decrement
    decrement() {
        if (this.counter > 1) this.counter--;
        this.addToCartForm.controls['quantity'].setValue(this.counter);
    }

    get isDisabledAtc(): boolean {
        return this.isDisableAtc || this.counter > this.product.stock;
    }

    // Add to cart
    addToCart() {
        this.formSubmitted = true;
        if (this.addToCartForm.valid) {
            this.isDisableAtc = true;
            var data = this.addToCartForm.getRawValue();
            const post_data = new FormData();
            post_data.append('product_id', data.product_id);
            post_data.append('quantity', data.quantity);
            for (let optid in data.prodoption) {
                post_data.append('option[' + optid + ']', data.prodoption[optid]);
            }
            this.cartService.addToCart(post_data).subscribe(response => {
                this.formSubmitted = false;
                this.isDisableAtc = false;
                if (response.error && response.error.option) {
                }
                if (response.status) {
                    this.cartService.updateCartState();
                    this.modalService.dismissAll();
                }
            });
        }
    }

    // Buy Now
    buyNow() {
        this.formSubmitted = true;
        if (this.addToCartForm.valid) {
            this.isDisableAtc = true;
            var data = this.addToCartForm.getRawValue();
            const post_data = new FormData();
            post_data.append("product_id", data.product_id);
            post_data.append("quantity", data.quantity);
            post_data.append("option", data.prodoption);
            this.cartService.addToCart(post_data).subscribe(response => {
                this.formSubmitted = false;
                this.isDisableAtc = false;
                if (response.error && response.error.option) {
                }
                if (response.status) {
                    this.cartService.updateCartState();
                    this.router.navigateByUrl('/checkout');
                }
            });
        }
    }

    async openModal() {
        //await this.productService.getProduct(this.product.product_id).subscribe(response => {
        //    this.product = response;
        //});
        this.formSubmitted = false;
        this.addToCartForm.get('prodoption').reset();
        Object.entries(this.optionControls).forEach((optid, optctrl) => {
            let opt_id: any = optid;
            this.addToCartForm.get('prodoption.' + parseInt(opt_id)).setValue('');
        });

        this.modalOpen = true;
        if (isPlatformBrowser(this.platformId)) { // For SSR 
            this.modalService.open(this.QuickView, {
                size: 'lg',
                ariaLabelledBy: 'modal-basic-title',
                centered: true,
                windowClass: 'Quickview'
            }).result.then((result) => {
                `Result ${result}`
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnDestroy() {
        if (this.modalOpen) {
            this.modalService.dismissAll();
        }
    }
}