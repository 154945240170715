import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  	constructor(
  		private spinner: NgxSpinnerService,
  		private router: Router
  	) {
  		this.router.events.subscribe((event) => {
  			if (event instanceof NavigationStart) {
        	this.spinner.show('main_loader');
      	} else if (event instanceof NavigationEnd) {
          this.spinner.hide('main_loader');
        } else if (event instanceof NavigationCancel) {
          this.spinner.hide('main_loader');
        }
      });
  	}

  	ngOnInit() {
  	}

}
