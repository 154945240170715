import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { CartService } from "../../../services/cart.service";

@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.component.html',
  styleUrls: ['./mini-cart.component.scss']

})
export class MiniCartComponent implements OnInit, OnDestroy {

  showChkoutBtn: boolean = true;
  canbeChng: boolean = true;

  constructor(
    private router: Router,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    if (this.router.url.match(/\/checkout/g)) {
      this.showChkoutBtn = false;
      this.canbeChng = false;
    }
    if (this.router.url == '/checkout/cart') {
      this.canbeChng = true;
      this.showChkoutBtn = true;
    }
  }

  get products() {
    return this.cartService.items();
  }

  get cartTotal() {
    return this.cartService.subTotal();
  }

  get cartQty() {
    return this.cartService.totalQty();
  }

  gotoCheckout() {
    this.showChkoutBtn = false;
    this.canbeChng = false;
    if (this.router.url.match(/\/checkout/g)) {
      this.closeCart();
    }
    this.router.navigateByUrl('/checkout');
  }

  gotoCart() {
    this.canbeChng = true;
    this.showChkoutBtn = true;
    if (this.router.url.match(/\/checkout/g)) {
      this.closeCart();
    }
    this.router.navigateByUrl('/checkout/cart');
  }

  removeItem(cart_item_id) {
    this.cartService.removeCartItem(cart_item_id).subscribe((response) => {
      if (response.status == 1) {
        this.cartService.updateCartState();
      }
    });
  }

  // Increament
  increment(cart_item_id, qty:number = 1) {
    var quantity = 0;
    var item = this.cartService.items(cart_item_id);
    quantity = parseInt(item.quantity) + qty;
    this.updateCart(cart_item_id, quantity);

  }
  // Decrement
  decrement(cart_item_id, qty:number = 1) {
    var quantity = 0;
    var item = this.cartService.items(cart_item_id);
    quantity = parseInt(item.quantity) - qty;
    this.updateCart(cart_item_id, quantity, 'dec');
  }

  updateCart(cart_item_id, qty, type='inc') {
    this.cartService.updateCart(cart_item_id, qty).subscribe(response => {
      if(response.status==1){
        var quty = 1;
        if(type == 'dec'){
          quty = -1;
        }
        this.cartService.updateCartState();
      }
    });
  }

  get showModal() {
    if (this.cartService.miniCartModal) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('mini-cart');
    }

    return this.cartService.miniCartModal;
  }

  closeCart() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('mini-cart');
    this.cartService.miniCartModal = false;
  }

  ngOnDestroy(): void {
    this.closeCart();
  }

}
