<div class="icon-nav">
	<ul>
		<li class="onhover-div mobile-cart">
			<div>
				<a [routerLink]="['/checkout/cart']">
					<img src="assets/images/icon/cart.png" class="img-fluid" alt="">
					<i class="ti-shopping-cart"></i>
				</a>
			</div>
			<span class="cart_qty_cls">{{ cartQty }}</span>
			<ul class="show-div shopping-cart" *ngIf='!cartProducts.length'>
				<h5>Your cart is currently empty.</h5>
			</ul>
			<ul class="show-div shopping-cart" *ngIf='cartProducts.length'>
				<div class="mini-cart-header">In Your Bag</div>
				<li *ngFor="let product of cartProducts">
					<div class="media">
						<a [routerLink]="['/shop/', product.handle]">
						<img class="mr-3" [src]="product.image" [alt]="product.name">
						</a>
						<div class="media-body">
							<a [routerLink]="['/p', product.handle]">
								<h4>{{ product.name | titlecase }}</h4>
								<ng-container *ngFor="let option of product.option">
                                    <small>{{option.name}}: {{option.value}}</small>
                                </ng-container>
							</a>
							<h4>
								<span>
								{{ product.quantity }} x 
								{{ product.special ? product.special : product.price }}
								</span>
							</h4>
						</div>
					</div>
					<div class="close-circle" (click)="removeItem(product.cart_item_id)">
						<a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
					</div>
				</li>
				<li>
					<div class="total">
						<h5>total: <span>{{ cartTotal }}</span></h5>
					</div>
				</li>
				<li>
					<div class="buttons">
						<a [routerLink]="['/checkout']" class="checkout">checkout</a>
						<a [routerLink]="['/checkout/cart']" class="view-cart">view cart</a>
						
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>