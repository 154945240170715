import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';
import { SettingsService } from './settings.service';
import { Currency } from '../classes/currency';

@Injectable({
	providedIn: 'root'
})

export class LocalisationService extends BaseService  {
	public allCurrency: BehaviorSubject<Currency[]> = new BehaviorSubject<Currency[]>([]);
	public currCurrency: BehaviorSubject<Currency> = new BehaviorSubject<Currency>(null);
	public configs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	private loccurrencies: Currency[];
	private loccurrency: Currency;
	private setting: any = null;

	getConfig(key = '') {
		if (key != '')
			if (key in this.config)
				return this.config[key];
			else
				return '';
		else
        	return this.config;
    }

    get config() {
    	this.configs.subscribe((config: any) => {
            this.setting = config;
        });
        return this.setting;
    }

    get currency() {
    	this.currCurrency.subscribe((currency: any) => {
            this.loccurrency = currency;
        });
        return this.loccurrency;
    }

    get currencies() {
    	this.allCurrency.subscribe((currencies: any) => {
            this.loccurrencies = currencies;
        });
        return this.loccurrencies;
    }

	public loadCurrencies() {
		this.getCurrencies().subscribe(resp => {
			this.allCurrency.next(resp.currencies);
			this.currCurrency.next(resp.currency);
		});
	}

	public getCurrencies(): Observable<any> {
		return this.getApi('common/currency');
	}

	public setCurrency(code:string): Observable<any> {
		let postdata = new FormData();
		postdata.append('code', code);

		return this.postApi('common/currency/change', postdata);
	}

	public getCountries(): Observable<any> {
		return this.getApi('common/country/all');
	}

	public getCountry(country_id: number) : Observable<any> {
		return this.getApi('common/country&country_id=' + country_id);
	}
}
