import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-product',
    templateUrl: './product-component.html',
    styleUrls: ['./product-component.scss']
})
export class ProductComponent implements OnInit {

    constructor( private router: Router, private spinner: NgxSpinnerService ) { 
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.spinner.hide('main_loader');
            }
        });
    }

    ngOnInit(): void {
    }
}