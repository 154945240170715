import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { CartService } from "../../../services/cart.service";
import { LocalisationService } from "../../../../shared/services/localisation.service";

@Component({
	selector: 'app-product-box',
	templateUrl: './product-box.component.html',
	styleUrls: ['./product-box.component.scss']
})

export class ProductBoxComponent implements OnInit {
	@ViewChild("quickView") QuickView: QuickViewComponent;
	@ViewChild("cartModal") CartModal: CartModalComponent;
	@Input() product: Product;
	@Input() cartModal: boolean = false;
	@Input() loader: boolean = false;

	private opttypes = ['select', 'radio', 'checkbox', 'image'];
	newprice: any = 0;
	newsprice: any = 0;
	doptnames: string = '';
	quantity: number = 1;
	submitted: boolean = false;

	constructor(		
		private router: Router,
		private toastrService: ToastrService,
		private localeService: LocalisationService,
		private cartService: CartService
	) {}

	ngOnInit(): void {
		if (this.loader) {
			setTimeout(() => {
				this.loader = false;
			}, 1000);
		}

		this.quantity = +this.product.minimum;
		this.newprice = parseFloat(this.product.price);
  		this.newsprice = parseFloat(this.product.special);

		if (this.product.options.length > 0) {
  			let nprice = 0;
  			this.product.options.forEach(option => {
      			if (this.opttypes.includes(option.type)) {
      				let doptval = option.product_option_value.find(ov => ov.isdefault);
      				if (doptval != undefined) {
      					nprice += parseFloat(doptval.price);
      					if (this.doptnames) this.doptnames += ' / ';
      					this.doptnames += doptval.name;
      				}
      			}
      		});

      		this.newprice += nprice;
      		if (parseFloat(this.product.special) > 0) {
      			this.newsprice += nprice;
      		}
  		}
		if(this.product.name==="Organic Turmeric  Powder"){
			
			this.newsprice=this.product.price;

		}
	}

	get config() {
    	return this.localeService.config;
  	}

  	get currency() {
    	return this.localeService.currency;
  	}

  	get isAddedToWishlist() {
  		return this.cartService.isAddedToWishlist(this.product.product_id);
  	}

  	// Increament
	increment() {
		if (this.submitted) return;
		this.quantity++;
	}

	// Decrement
	decrement() {
		if (this.submitted) return;
		if (this.quantity > 1) this.quantity--;
	}

	addToCart() {
		if (this.submitted) return;
		this.submitted = true;
		const post_data = new FormData();
	    post_data.append("product_id", this.product.product_id.toString());
        post_data.append("quantity", this.quantity.toString());
	    this.product.options.forEach(option => {
  			if (this.opttypes.includes(option.type)) {
  				let doptval = option.product_option_value.find(ov => ov.isdefault);
  				if (doptval != undefined) {
  					post_data.append('option[' + option.product_option_id + ']', doptval.product_option_value_id);
  				}
  			}
  		});
		this.cartService.addToCart(post_data).subscribe(response => {
			if(response.error && response.error.option){
				this.router.navigateByUrl('/shop/' + this.product.handle);
			} else if (response.error) {
				this.toastrService.error(response.error);
			} else if (response.status == '1') {
				this.toastrService.success('Successfully added to cart.');
				this.cartService.updateCartState();
				this.cartService.miniCartModal = true;
				this.quantity = +this.product.minimum;
			}
			this.submitted = false;
		},
		error => {
			this.submitted = false;
		});
	}

	addToWishlist() {
		if (this.submitted) return;
		this.submitted = true;
		this.cartService.addToWishlist(this.product.product_id).subscribe(response => {
			if (response.redirect) {
				this.router.navigateByUrl(response.redirect);
			} else if (response.error) {
				this.toastrService.error(response.error);
			} else {
				this.toastrService.success('Successfully added to wishlist.');
				this.cartService.updateWishlistState();
			}
			this.submitted = false;
		},
		error => {
			this.submitted = false;
		});
    }

    removeFromWishlist() {
    	if (this.submitted) return;
    	this.submitted = true;
		this.cartService.removeFromWishlist(this.product.product_id).subscribe(response => {
			if (response.error) {
				this.toastrService.error(response.error);
			} else {
				this.toastrService.success('Successfully removed from wishlist.');
				this.cartService.updateWishlistState();
			}
			this.submitted = false;
		},
		error => {
			this.submitted = false;
		});
    }
}