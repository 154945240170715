import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalisationService } from "../../shared/services/localisation.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public config: any = null;
  public today: number = Date.now();

  constructor(
    private router: Router,
    private localeService: LocalisationService
  ) {
    this.config = this.localeService.config;
  }

  ngOnInit(): void {
  }

}
