import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ContentModalComponent } from "../modal/content/content.component";
import { VideoModalComponent } from "../modal/video-modal/video-modal.component";
import { LocalisationService } from "../../../shared/services/localisation.service";

@Component({
	selector: 'app-awardmedia-box',
	templateUrl: './award-media-box.component.html',
	styleUrls: ['./award-media-box.component.scss']
})

export class AwardMediaBoxComponent implements OnInit {
	@ViewChild("videoModal") VideoModal: VideoModalComponent;
	@ViewChild("imgprevModal") ImgprevModal: ContentModalComponent;
	@Input() awardmedia: any;
	@Input() loader: boolean = false;

	constructor(
		private localeService: LocalisationService
	) {}

	ngOnInit(): void {
		if (this.loader) {
			setTimeout(() => {
				this.loader = false;
			}, 1000);
		}
	}

	get config() {
    	return this.localeService.config;
  	}

}