import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';

@Component({
    selector: 'app-content-with-image',
    templateUrl: './content-with-image.component.html',
    styleUrls: ['./content-with-image.component.scss']
})

export class ContentWithImageComponent implements OnChanges {

    @Input() class: string;
    @Input() seeMore: boolean;
    @Input() imageLeft: boolean;
    @Input() content: any;

    public datacont: any = {
        'title': '',
        'content': '',
        'image': ''
    };

    constructor() {}

    ngOnChanges(change: SimpleChanges) {
        if (change.content && change.content.currentValue) {
            this.datacont = change.content.currentValue;
        }
    }
}
