import {Component,OnInit,Injectable,PLATFORM_ID,Inject,} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

import { CartService } from "../../services/cart.service";
import { LocalisationService } from "../../services/localisation.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  public languages = [
    {
      name: "English",
      code: "en",
    },
    {
      name: "French",
      code: "fr",
    },
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    private localeService: LocalisationService,
    private cartService: CartService
  ) {
  }

  ngOnInit(): void {}

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
  }

  /*get currencies() {
    return this.localeService.currencies;
  }

  changeCurrency(currency: any) {
    this.localeService.setCurrency(currency.code).subscribe(resp => {
      this.localeService.currCurrency.next(currency);
      let currentUrl = this.router.url;
      //this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      //    this.router.navigate([currentUrl]);
      //});
    });
  }*/

  get cartProducts() {
    return this.cartService.items();
  }

  get cartTotal() {
    return this.cartService.subTotal();
  }

  get cartQty() {
    return this.cartService.totalQty();
  }

  removeItem(cart_item_id) {
    this.cartService.removeCartItem(cart_item_id).subscribe((response) => {
      if (response.status == 1) {
        this.cartService.updateCartState();
      }
    });
  }
}
