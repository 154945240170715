import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  url: string = ''; 

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService
  ) {  
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.spinner.show('main_loader');
      } else if (event instanceof NavigationEnd) {
        this.url = event.url;
        this.spinner.hide('main_loader');
      } else if (event instanceof NavigationCancel) {
        this.spinner.hide('main_loader');
      }
    });
  }

  ngOnInit(): void {
  }

}
