import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';
import { CartService } from "./cart.service";

@Injectable({
	providedIn: 'root'
})

export class AuthService extends BaseService {
	private logged: boolean = false;

	constructor(
		protected http: HttpClient,
		private cartService: CartService  
	) {
		super(http);
	}

	get isLogged() {
        return this.logged;
    }

    public setIsLogged(val) {
    	this.logged = val;
    }

    public async checkLoggedIn() {
	    let resp = await this.getApi('account/login/isLogedIn').toPromise();
    	this.logged = resp.logged;
    	if (!resp.logged) {
    		this.cartService.updateCartState();
    	}
	}

	public checkLogged(): Promise<any> {
	    return new Promise((resolve, reject) => {
		    this.getApi('account/login/isLogedIn').subscribe(response => {
		    	this.logged = response.logged;		    	
	            resolve(true);
	        });
        });
	}

	public accountRegister(post_data): any {
		return this.postApi('account/register', post_data);
	}

	public accountSuccess(): any {
		return this.getApi('account/success');
	}

	public registerConfigs(): any {
		return this.getApi('account/register');
	}

	public accountlogin(post_data): any {
		return this.postApi('account/login', post_data);
	}

	public accountlogout(): any {
		return this.getApi('account/logout');
	}

	public isBackendLogin(): any {
		return this.getApi('account/login/isLogedIn');
	}

	public getAuthUser(): any {
		if (this.logged) {
			var user: any = sessionStorage.getItem('user_info');
			return JSON.parse(user);
		}
		return false;
	}

	public emailverifySuccess(data): any {
		return this.postApi('account/email_verify', data)
	}

	public resetPassword(data): any {
		return this.postApi('account/reset', data);
	}

	public resendEmailVerify(data): any {
		return this.postApi('account/email_verify/resend', data);
	}
}