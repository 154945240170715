import { Injectable, HostListener } from '@angular/core';

import { BaseService } from './base.service';

// Menu
export interface Menu {
	id?: number;
	handle?: string;
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService extends BaseService {
	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event ? ) {
		this.screenWidth = window.innerWidth;
	}

	public getMenu(): any {
		return this.getApi('common/menu');
	}
}