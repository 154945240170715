<!--<div *ngIf="showPromo" class="promo-bar">
    <div class="alert alert-dismissible text-center">
        <button type="button" class="close" (click)="hidePromo()"><span> <i class="fa fa-times" aria-hidden="true"></i> </span></button>
        <span> <i class="fa fa-info-circle" aria-hidden="true"></i> </span> FAST DELIVERY DURING THE LOCKDOWN. FREE SHIPPING ON PREPAID ORDERS
    </div>
</div>-->

<header [ngClass]="class" [class.sticky]="sticky">
    <div class="alert-shop">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    For placing order, please WhatsApp on <a href="https://wa.me/7864909172/?text=I am interested"
                        target="_blank">7864909172</a>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-md-2 col-6">
                <div class="brand-logo">
                    <a routerLink="/" id="headerlogo">
                        <img [src]="themeLogo" class="img-fluid" alt="logo" />
                    </a>
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-6">
                <div class="menu-right">
                    <app-menu></app-menu>
                </div>
            </div>
            <div class="col-lg-2 col-md-2">
                <ul class="header-dropdown">
                    <li class="mobile-wishlist mr-4">
                        <a [routerLink]="['/account/wishlist']">

                            <svg width="25" height="22" viewBox="0 0 25 22" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.5209 21.5153C11.9826 21.5117 11.4609 21.3219 11.038 20.9764C10.6199 20.6478 10.1002 20.2536 9.50445 19.82C6.96981 17.9409 3.16784 15.089 1.22919 11.8035C0.176592 10.2314 -0.223158 8.28685 0.120033 6.4062C0.463174 4.52556 1.52032 2.86697 3.05414 1.8024C4.37382 0.834027 5.98738 0.395237 7.59529 0.566833C9.20348 0.738444 10.6968 1.50877 11.7987 2.73555L12.1155 3.0773C12.2213 3.19259 12.3676 3.25888 12.5212 3.26123C12.679 3.26269 12.8306 3.19581 12.9393 3.0773L13.2561 2.73555C14.268 1.59295 15.6233 0.839659 17.1008 0.598524C18.5786 0.357689 20.0912 0.642819 21.3925 1.40814C23.118 2.3835 24.3622 4.07407 24.8142 6.05738C25.2666 8.04068 24.8841 10.1299 23.7625 11.8035C21.8488 15.1022 18.0217 17.9409 15.4872 19.82C14.8915 20.2536 14.3721 20.6478 13.9537 20.9764H13.9534C13.5441 21.3105 13.0422 21.4991 12.5215 21.5153H12.5209ZM6.81795 1.80238C5.73254 1.80033 4.67537 2.15968 3.80186 2.8273C2.51839 3.69767 1.63358 5.07344 1.35122 6.63643C1.0689 8.19934 1.41374 9.81392 2.30627 11.1067C4.09327 14.1686 7.79364 16.9156 10.2397 18.7425C10.8479 19.1893 11.3803 19.5968 11.8111 19.9253C12.014 20.0896 12.2637 20.1788 12.5209 20.1788C12.778 20.1788 13.0278 20.0896 13.2306 19.9253C13.6615 19.5836 14.1939 19.1893 14.8021 18.7293C17.2478 16.9159 20.9486 14.1561 22.7355 11.0935V11.0938C23.686 9.71801 24.0167 7.98373 23.6427 6.33656C23.2684 4.68909 22.2263 3.29019 20.7838 2.49875C19.7233 1.8704 18.4876 1.63716 17.2817 1.83813C16.0761 2.03907 14.9728 2.66157 14.1556 3.6029L13.8387 3.94465V3.94436C13.5061 4.30224 13.0506 4.51023 12.5714 4.52284C12.0922 4.51022 11.6371 4.30224 11.3041 3.94436L10.9873 3.60261V3.6029C9.89817 2.42922 8.38809 1.77715 6.81784 1.80231L6.81795 1.80238Z"
                                    fill="black" />
                            </svg>

                        </a>
                    </li>
                    <li class="onhover-dropdown mobile-account mr-4" *ngIf="!isLogedIn">
                        <a [routerLink]="['/account/login']"> <svg width="18" height="24" viewBox="0 0 18 24"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.083 11.9274C10.6648 11.9274 12.1816 11.2992 13.3001 10.1806C14.4183 9.06234 15.0469 7.5453 15.0469 5.96387C15.0469 4.38206 14.4183 2.86509 13.3001 1.74681C12.1815 0.628223 10.6647 0 9.083 0C7.50127 0 5.98445 0.628253 4.86594 1.74681C3.74767 2.86509 3.11913 4.38214 3.11913 5.96387C3.12275 7.54445 3.75221 9.05911 4.86988 10.1768C5.98754 11.2944 7.5025 11.9239 9.08308 11.9275L9.083 11.9274ZM9.083 1.34912C10.307 1.34912 11.4807 1.83522 12.3459 2.70078C13.2112 3.56609 13.6976 4.73973 13.6976 5.96372C13.6976 7.18739 13.2112 8.36111 12.3459 9.22665C11.4806 10.092 10.307 10.578 9.083 10.578C7.85902 10.578 6.68531 10.0919 5.82007 9.22665C4.95477 8.36103 4.46841 7.18739 4.46841 5.96372C4.46841 4.73973 4.95481 3.56602 5.82007 2.70078C6.68538 1.83517 7.85902 1.34912 9.083 1.34912Z"
                                    fill="black" />
                                <path
                                    d="M2.80906 23.3431H15.3573C15.8645 23.3395 16.3497 23.1365 16.7081 22.7781C17.0668 22.4194 17.2698 21.9342 17.2734 21.427C17.2734 18.4987 15.7109 15.7926 13.175 14.3286C10.6388 12.8643 7.5141 12.8643 4.97839 14.3286C2.44214 15.7926 0.879988 18.4987 0.879988 21.427C0.883301 21.9366 1.0884 22.4239 1.44981 22.7826C1.81123 23.1416 2.30001 23.3431 2.80934 23.3431L2.80906 23.3431ZM9.08319 14.5998C10.8999 14.5926 12.6446 15.3082 13.933 16.5891C15.2215 17.8697 15.9473 19.6105 15.9509 21.4269C15.9509 21.7401 15.6973 21.9937 15.3844 21.9937H2.83615C2.68587 21.9937 2.5416 21.9341 2.43528 21.8278C2.32897 21.7214 2.26934 21.5772 2.26934 21.4269C2.26934 19.6186 2.98675 17.8842 4.26434 16.6041C5.5416 15.3241 7.27492 14.6031 9.08317 14.5998L9.08319 14.5998Z"
                                    fill="black" />
                            </svg> </a>
                        <ul class="onhover-show-div d-none">
                            <li>
                                <a [routerLink]="['/account/login']">

                                    Sign In
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/account/register']">
                                    <i class="fa fa-sign-out" aria-hidden="true"></i> Register
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="onhover-dropdown mobile-account" *ngIf="isLogedIn">
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <ul class="onhover-show-div">
                            <li>
                                <a [routerLink]="['/account/dashboard']">
                                    <span> <i aria-hidden="true" class="fa fa-user-circle"></i> </span> My Account
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/account/orders']">
                                    <span><i aria-hidden="true" class="fa fa-shopping-cart"></i></span> My Order
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/account/address']">
                                    <span><i aria-hidden="true" class="fa fa-address-book-o"></i></span> My Address
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/account/logout']">
                                    <span><i aria-hidden="true" class="fa fa-sign-out"></i></span> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="onhover-div mobile-cart">
                        <a [routerLink]="['/checkout/cart']">

                            <svg (click)="showCart($event)" width="26" height="24" viewBox="0 0 26 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M25.844 4.42608C25.7082 4.26319 25.5182 4.15447 25.301 4.15447H6.61921L5.83185 0.543026C5.75041 0.217239 5.4788 0 5.15301 0H0.916885C0.536743 0 0.210938 0.2987 0.210938 0.705948C0.210938 1.1132 0.509638 1.4119 0.916885 1.4119H4.60979L7.18934 13.5768C7.56968 15.342 9.17171 16.6454 11.0181 16.6454H20.1146C21.9339 16.6454 23.5088 15.3962 23.9433 13.6313L25.9798 5.02358C26.034 4.80633 25.9798 4.58908 25.844 4.4262L25.844 4.42608ZM22.5856 13.3053C22.314 14.4458 21.2823 15.2604 20.1146 15.2604L11.0181 15.2602C9.82325 15.2602 8.79136 14.4185 8.51992 13.2508L6.8906 5.5663H24.4319L22.5856 13.3053Z"
                                    fill="black" />
                                <path
                                    d="M11.5666 17.9758C10.046 17.9758 8.79688 19.2249 8.79688 20.7455C8.79688 22.2661 10.046 23.5152 11.5666 23.5152C13.0872 23.5152 14.3363 22.2661 14.3363 20.7455C14.3636 19.2249 13.1145 17.9758 11.5666 17.9758ZM11.5666 22.1305C10.8063 22.1305 10.1818 21.506 10.1818 20.7457C10.1818 19.9854 10.8063 19.3609 11.5666 19.3609C12.3269 19.3609 12.9514 19.9854 12.9514 20.7457C12.9516 21.5058 12.3542 22.1305 11.5666 22.1305Z"
                                    fill="black" />
                                <path
                                    d="M19.3557 17.9758C17.8351 17.9758 16.5859 19.2249 16.5859 20.7455C16.5859 22.2661 17.8351 23.5152 19.3557 23.5152C20.8762 23.5152 22.1254 22.2661 22.1254 20.7455C22.1254 19.2249 20.8762 17.9758 19.3557 17.9758ZM19.3557 22.1305C18.5954 22.1305 17.9709 21.506 17.9709 20.7457C17.9707 19.9852 18.5954 19.3608 19.3557 19.3608C20.116 19.3608 20.7405 19.9852 20.7405 20.7455C20.7405 21.5058 20.116 22.1305 19.3557 22.1305Z"
                                    fill="black" />
                            </svg>

                            <span class="cart_qty_cls" (click)="showCart($event)">{{ cartQty }}</span>
                        </a>
                    </li>
                </ul>

            </div>
            <!--<div class="col-lg-2 col-md-2 d-none d-sm-block">
                <div class="social">
                    <ul>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/parvatafoods"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                        </li>

                        <li>
                            <a target="_blank" href="https://twitter.com/parvatafoods"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.instagram.com/parvata.organic"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                        </li>
                    </ul>
                </div>
            </div>-->
        </div>
    </div>
</header>