import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  	selector: 'app-blog',
  	templateUrl: './blog.component.html',
  	styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  	public url : any;

  	constructor(private router: Router) {  
	    this.router.events.subscribe((event) => {
	        if (event instanceof NavigationEnd) {
	            this.url = event.url;
	        }
	    });
  	}

  	ngOnInit() {
  	}

}
