import { OnInit, Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';

import { Currency } from '../classes/currency';
import { BaseService } from './base.service';
import { LocalisationService } from './localisation.service';

@Injectable({
  	providedIn: 'root'
})

export class SettingsService extends BaseService {
	private token: any = sessionStorage.getItem('api_token');
	private configs: any = null;

	constructor(
		protected http: HttpClient,
		private localeService: LocalisationService
	) {
		super(http);
	}

	get config() {
		if (sessionStorage['api_config'])
			return JSON.parse(sessionStorage['api_config']);
		else
        	return this.configs;
    }

    getConfig(key = '') {
		if (key != '')
			if (key in this.config)
				return this.config[key];
			else
				return '';
		else
        	return this.config;
    }

    get apiToken() {
        return this.token;
    }

	public getApiToken(): Promise<any> {
		if (!sessionStorage['api_token']) {
		    return new Promise((resolve, reject) => {
			    this.apiGetToken().subscribe(response => {
			    	if (response.api_token) {
			    		this.token = response.api_token;
		            	sessionStorage.setItem('api_token', response.api_token);
		            	this.apiConfig().pipe(
			      			mergeMap((respconfig: any) => {
			      				return forkJoin([[respconfig], this.localeService.getCurrencies()]);
			      			})
			      		).subscribe(([respconfig, respcurr]) => {
			      			if (!respconfig.error) {
					    		this.configs = respconfig;
					    		this.localeService.configs.next(respconfig);
					    		//sessionStorage.setItem('api_config', JSON.stringify(respconfig));
				            }
				            this.localeService.allCurrency.next(respcurr.currencies);
			      			this.localeService.currCurrency.next(respcurr.currency);
				            resolve(true);
				        });
		            } else {
		            	resolve(true);
		            }
		        });
	        });
	    } else {
	      	return new Promise((resolve, reject) => {
	      		this.apiConfig().pipe(
	      			mergeMap((respconfig: any) => {
	      				return forkJoin([[respconfig], this.localeService.getCurrencies()]);
	      			})
	      		).subscribe(([respconfig, respcurr]) => {
	      			if (!respconfig.error) {
			    		this.configs = respconfig;
			    		this.localeService.configs.next(respconfig);
		            }
		            this.localeService.allCurrency.next(respcurr.currencies);
	      			this.localeService.currCurrency.next(respcurr.currency);
		            resolve(true);
		        });
	      	});
	    }
	}

	private apiGetToken(): Observable<any> {
	    return this.getApi('common/apitoken');
	}

	private apiConfig(): Observable<any> {
	    return this.getApi('common/config&api_token=' + this.apiToken);
	}
}