import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { CartService } from "../../../shared/services/cart.service";

@Component({
  selector: "app-coupon",
  templateUrl: "./coupon.component.html",
  styleUrls: ["./coupon.component.scss"],
})
export class CouponComponent implements OnInit {
  public couponForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private cartService: CartService,
    private toastrService: ToastrService
  ) {
    this.couponForm = fb.group({
      coupon: [""],
    });
  }

  ngOnInit(): void {}

  onCouponSubmit() {
    this.cartService.couponApply(this.couponForm.value.coupon).subscribe((response) => {
      this.cartService.updateStateTotals();
      if (response.error) {
        this.toastrService.error(response.error);
      } else {
        this.toastrService.success(response.success);        
        this.couponForm.reset();
      }
    });
  }
}
