<div>
    <div class="img-wrapper">
      <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '280px' }"></ngx-skeleton-loader>
    </div>
    <div class="am-detail">
      <h4> 
        <ngx-skeleton-loader
          [theme]="{ 'background-color': '#e8e8e8', width: '130px', 'border-radius': '15px',  height: '15px', 'margin-bottom': '6px'}">
        </ngx-skeleton-loader>
      </h4>
    </div>
</div>