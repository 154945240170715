import { Component, OnInit, Input, Optional } from '@angular/core';
import { isArray } from 'rxjs-compat/util/isArray';

@Component({
  	selector: 'app-breadcrumb',
  	templateUrl: './breadcrumb.component.html',
  	styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {

  	@Input() title: string;
  	@Input() breadcrumb: [];

  	constructor() {
  	}

  	ngOnInit() : void {
  		if (!isArray(this.breadcrumb)) {
  			this.breadcrumb = [];
  		}
  	}
}