<footer [class]="class">
  <div class="top-footer">
    <div class="footer-container">

      <div class="row footer-theme partition-f">
        <div class="col-lg-3 col-md-6">

          <div class="footer-contant">
            <!--<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>-->

            <div class="sub-title">
              <div class="footer-title">
                <h4>About Us</h4>
              </div>
            </div>

            <p>Parvata’s idea germinated in the young minds of Anurag & Siddhi, during a trek on the hilly terrains of
              Sikkim, undertaken in their Shodh Yatra course at IIM-Ahmedabad.</p>


          </div>
        </div>
        <div class="col-lg-3">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Let's Chat</h4>
            </div>
            <div class="footer-contant">
              <ul class="contact-list">
                <li><a href="https://wa.me/7864909172/?text=I am interested" target="_blank">7864909172</a></li>
                <!-- <li *ngIf="config.store_phone">{{config.store_phone}}</li> -->
                <li *ngIf="config.store_email">{{config.store_email}}</li>
              </ul>
              <div class="social">
                <ul class="d-flex">
                  <li>
                    <a href="https://wa.me/7864909172/?text=I am interested" target="_blank">
                      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.5029 0H11.4971C5.15631 0 0 5.15775 0 11.5C0 14.0156 0.81075 16.3472 2.18931 18.2404L0.756125 22.5127L5.17644 21.0996C6.99487 22.3043 9.16406 23 11.5029 23C17.8437 23 23 17.8408 23 11.5C23 5.15919 17.8437 0 11.5029 0ZM18.1944 16.2394C17.917 17.0229 16.8159 17.6726 15.9376 17.8624C15.3367 17.9903 14.5518 18.0924 11.9097 16.997C8.53012 15.5969 6.35375 12.1627 6.18412 11.9399C6.02169 11.7171 4.8185 10.1214 4.8185 8.47119C4.8185 6.82094 5.65656 6.01737 5.99438 5.67238C6.27181 5.38919 6.73037 5.25981 7.17025 5.25981C7.31256 5.25981 7.4405 5.267 7.5555 5.27275C7.89331 5.28713 8.06294 5.30725 8.28575 5.84056C8.56319 6.509 9.23881 8.15925 9.31931 8.32888C9.40125 8.4985 9.48319 8.7285 9.36819 8.95131C9.26038 9.18131 9.1655 9.28337 8.99587 9.47888C8.82625 9.67437 8.66525 9.82388 8.49562 10.0337C8.34037 10.2163 8.165 10.4118 8.3605 10.7496C8.556 11.0802 9.23162 12.1828 10.2264 13.0683C11.5101 14.2111 12.5508 14.5763 12.9231 14.7315C13.2006 14.8465 13.5312 14.8192 13.7339 14.6036C13.9912 14.3261 14.3089 13.8661 14.6323 13.4133C14.8623 13.0884 15.1527 13.0482 15.4574 13.1632C15.7679 13.271 17.411 14.0832 17.7488 14.2514C18.0866 14.421 18.3094 14.5015 18.3914 14.6438C18.4719 14.7861 18.4719 15.4546 18.1944 16.2394Z"
                          fill="white" />
                      </svg>

                    </a>
                  </li>
                  <li>
                    <a>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_1726_4548" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                          width="25" height="24">
                          <path
                            d="M24.7861 14.3467V23.4021H19.5324V14.9536C19.5324 12.8322 18.7738 11.3835 16.8716 11.3835C15.4199 11.3835 14.5576 12.3588 14.1767 13.3032C14.0384 13.6407 14.0028 14.1094 14.0028 14.5828V23.4017H8.74862C8.74862 23.4017 8.81915 9.09265 8.74862 7.61146H14.0032V9.84909C13.9926 9.86671 13.9777 9.88394 13.9683 9.90078H14.0032V9.84909C14.7014 8.7755 15.9465 7.24068 18.7382 7.24068C22.1947 7.24068 24.7861 9.49749 24.7861 14.3467ZM3.25234 0C5.04995 0 6.15446 1.17892 6.18933 2.72783C6.18933 4.24386 5.04995 5.45684 3.21747 5.45684H3.18338C1.42103 5.45684 0.279297 4.24386 0.279297 2.72783C0.279297 1.17892 1.45511 0 3.25234 0ZM0.591568 23.4021V7.61146H5.84375V23.4021H0.591568Z"
                            fill="white" />
                        </mask>
                        <g mask="url(#mask0_1726_4548)">
                          <path
                            d="M24.7881 14.2881V23.3435H19.5343V14.895C19.5343 12.7736 18.7758 11.3249 16.8735 11.3249C15.4219 11.3249 14.5595 12.3002 14.1787 13.2446C14.0404 13.5821 14.0047 14.0508 14.0047 14.5242V23.3431H8.75057C8.75057 23.3431 8.8211 9.03405 8.75057 7.55287H14.0051V9.7905C13.9945 9.80812 13.9796 9.82535 13.9702 9.84218H14.0051V9.7905C14.7033 8.71691 15.9485 7.18209 18.7401 7.18209C22.1966 7.18209 24.7881 9.4389 24.7881 14.2881ZM3.25429 -0.0585938C5.0519 -0.0585938 6.15641 1.12032 6.19128 2.66924C6.19128 4.18527 5.05191 5.39825 3.21942 5.39825H3.18533C1.42298 5.39825 0.28125 4.18527 0.28125 2.66924C0.28125 1.12032 1.45707 -0.0585938 3.25429 -0.0585938ZM0.593521 23.3435V7.55287H5.84571V23.3435H0.593521Z"
                            fill="white" />
                        </g>
                      </svg>

                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/parvata.organic">
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.56579 0C3.39408 0 0 3.39408 0 7.56579V17.4342C0 21.6059 3.39408 25 7.56579 25H17.4342C21.6059 25 25 21.6059 25 17.4342V7.56579C25 3.39408 21.6059 0 17.4342 0H7.56579ZM19.0789 4.60526C19.8059 4.60526 20.3947 5.19408 20.3947 5.92105C20.3947 6.64737 19.8059 7.23684 19.0789 7.23684C18.352 7.23684 17.7632 6.64737 17.7632 5.92105C17.7632 5.19408 18.352 4.60526 19.0789 4.60526ZM12.5 5.92105C16.1276 5.92105 19.0789 8.87237 19.0789 12.5C19.0789 16.1276 16.1276 19.0789 12.5 19.0789C8.87237 19.0789 5.92105 16.1276 5.92105 12.5C5.92105 8.87237 8.87237 5.92105 12.5 5.92105ZM12.5 7.89474C11.8952 7.89474 11.2964 8.01385 10.7376 8.24529C10.1789 8.47673 9.67123 8.81595 9.24359 9.24359C8.81595 9.67123 8.47673 10.1789 8.24529 10.7376C8.01385 11.2964 7.89474 11.8952 7.89474 12.5C7.89474 13.1048 8.01385 13.7036 8.24529 14.2624C8.47673 14.8211 8.81595 15.3288 9.24359 15.7564C9.67123 16.1841 10.1789 16.5233 10.7376 16.7547C11.2964 16.9861 11.8952 17.1053 12.5 17.1053C13.1048 17.1053 13.7036 16.9861 14.2624 16.7547C14.8211 16.5233 15.3288 16.1841 15.7564 15.7564C16.1841 15.3288 16.5233 14.8211 16.7547 14.2624C16.9861 13.7036 17.1053 13.1048 17.1053 12.5C17.1053 11.8952 16.9861 11.2964 16.7547 10.7376C16.5233 10.1789 16.1841 9.67123 15.7564 9.24359C15.3288 8.81595 14.8211 8.47673 14.2624 8.24529C13.7036 8.01385 13.1048 7.89474 12.5 7.89474Z"
                          fill="white" />
                      </svg>

                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.facebook.com/parvatafoods">
                      <svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_1726_4553" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                          width="13" height="24">
                          <path
                            d="M12.2248 11.966H8.55287V23.6751H3.11182V11.966H0.525391V7.82769H3.11182V5.15094C3.11182 3.23386 4.13003 0.238281 8.60287 0.238281L12.6348 0.252109V4.26926H9.70835C9.23197 4.26926 8.55469 4.48236 8.55469 5.39657V7.8285H12.7002L12.2248 11.966Z"
                            fill="white" />
                        </mask>
                        <g mask="url(#mask0_1726_4553)">
                          <path
                            d="M12.2228 11.966H8.55091V23.6751H3.10987V11.966H0.523438V7.82769H3.10987V5.15094C3.10987 3.23386 4.12807 0.238281 8.60091 0.238281L12.6328 0.252109V4.26926H9.7064C9.23002 4.26926 8.55273 4.48236 8.55273 5.39657V7.8285H12.6983L12.2228 11.966Z"
                            fill="white" />
                          <path d="M28.5893 -8.13281H-16.3183V32.0446H28.5893V-8.13281Z" fill="white" />
                        </g>
                      </svg>

                    </a>
                  </li>

                  <li>
                    <a target="_blank" href="https://twitter.com/parvatafoods">
                      <svg width="26px" height="26px" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.78251 21C16.3295 21 20.4592 14.4577 20.4592 8.7938C20.4592 8.60974 20.4592 8.42569 20.4512 8.24163C21.2515 7.63927 21.9478 6.87795 22.5 6.01624C21.7637 6.35925 20.9714 6.58514 20.1391 6.6939C20.9874 6.16683 21.6357 5.32185 21.9478 4.31791C21.1555 4.81152 20.2751 5.16289 19.3387 5.35531C18.5864 4.5187 17.522 4 16.3455 4C14.0806 4 12.2399 5.92421 12.2399 8.29183C12.2399 8.62648 12.2799 8.95276 12.3439 9.27067C8.93457 9.09498 5.90936 7.37992 3.88455 4.78642C3.53241 5.42224 3.33233 6.15846 3.33233 6.94488C3.33233 8.43406 4.06062 9.74754 5.15706 10.5172C4.48479 10.4921 3.85254 10.2997 3.30032 9.98179C3.30032 9.99852 3.30032 10.0153 3.30032 10.0404C3.30032 12.1152 4.71689 13.8553 6.58964 14.2485C6.2455 14.3489 5.88535 14.3991 5.5092 14.3991C5.2451 14.3991 4.989 14.374 4.7409 14.3238C5.2611 16.0305 6.78171 17.2687 8.57443 17.3022C7.16587 18.4567 5.39716 19.1427 3.47639 19.1427C3.14826 19.1427 2.82013 19.126 2.5 19.0842C4.30872 20.2889 6.46959 21 8.78251 21Z"
                          fill="#FFF" />
                      </svg>

                    </a>
                  </li>
                  <!-- <li>
                    <a target="_blank" href="https://www.instagram.com/parvata.organic"><i class="fa fa-instagram"
                        aria-hidden="true"></i></a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Address</h4>
            </div>
            <div class="footer-contant">

              <ul class="contact-list">
                <li *ngIf="config.store_address != 'Address'" innerHtml='{{config.store_address}}'></li>

              </ul>

            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Useful Links</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li><a [routerLink]="'/info/privacy-policy'">Privacy Policy</a></li>
                <li><a [routerLink]="'/info/shipping-delivery-policy'">Shipping and Delivery Policy</a></li>
                <li><a [routerLink]="'/info/cancellation-refund-policy'">Cancellation and Return Policy</a></li>
                <li><a [routerLink]="'/info/terms-condition'">Terms and Conditions</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sub-footer">
    <div class="container">

      <div class="row align-items-center">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} {{config.store_name}}</p>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <!--<div class="payment-card-bottom">
            <ul>
              <li>
                <a><img src="assets/images/icon/visa.png" alt=""></a>
              </li>
              <li>
                <a><img src="assets/images/icon/mastercard.png" alt=""></a>
              </li>
              <li>
                <a><img src="assets/images/icon/upi.png" alt=""></a>
              </li>
              <li>
                <a><img src="assets/images/icon/cc.png" alt=""></a>
              </li>
              <li>
                <a><img src="assets/images/icon/pu.png" alt=""></a>
              </li>
            </ul>
          </div>-->
          <!-- <h5 class="designe-by"> Designed by <span> Halfbutfull Design and Strategy </span> </h5> -->
          <h5 class="designe-by">
            Developed by
            <a class="max-logo ml-2" href="https://maxbridgesolution.com/" target="_blank">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="250px" height="83px"
                viewBox="0 0 250 83" enable-background="new 0 0 250 83" xml:space="preserve">
                <image id="image0" width="250" height="83" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAABTCAYAAAC2/xobAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
            AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAABQ
            mUlEQVR42u29eZxlV3Xf+917n+FONXRVV4+SWkNrbI0ICQk0YkkYgzFgwMHEYGLHOHGcPDzE9stg
            vyTPsTPZ7+MQO35J7LzY8QDYATNYgEBgCSQkoanVrVZLPVZ1V9dcd77n7L3X++Occ+tWqQe1JIZA
            LX2uuu490z7n7LXX9FtrKb5tpAF42w++HQCvPBoInc6/gyjP2KZhPKBFAxrnUwITgVcsxBvYM7SF
            haBC6C3KpTQa9QuGJX3Pps7iGxafe3pbxXZPLM9Of2xycvJ/7Nq1yzrvAQiMwTrHs8/s+fY9gnVa
            p28RBd++S3tAg/KrfnHaowQEjVfg8wUBNKnWdMMhUq9Ba1phiVYnpaNa5a6zb1TifzwIou8vB6bW
            m28Sa0UcRuzYcf4PVGu173vm6d0/i1bLl19xhYeM2ddpnb4X6NvI6JCxdsHoGUNbnX+X4p8AJZnE
            7+oSk9EoraBCFEUcX1i6uK3de7UJ3xcE8SVKKcqliLK0UC5lbKiC9hYtnnPPPfd9SZIcO/j8C7++
            d/czrcuv3JWKyLf39tdpnb5F9G1mdDiZZHcqoBOUsCogCIcRlS0IzaDCvoVehbGRe2w3+aAOh75P
            VFgN4hJhpUQcBYzGIdFCG7ylHCoMMd47nPds27rtRw8dPPgFcf6xvXv2LBaSfZ3W6budvgMYXa/8
            JZkt3lYl5qoTLOsSndoYvbRLM+le3mmr9+mhkfd6CS4MoxhTjjGlKqpUIiiVqQSKIXp0plrEARgU
            ShRGBxggiqLtmzZt+oETx6ePGBN0gPa3++7XaZ2+FfQdwOieVAd0TYRTAThDLx5lKRxh2YRDzYZ9
            iwTB+yUafqMqxbFRJUqlGqoUoUolTLWEiksEYZmyTwhmF7Hz01S8xzlHoPWqq23fvv3Oubn5z7o0
            nd+7Z0+HvpGwTuv03UvffkZXnsQEzJTGaJkah57arc577c6r0jD+sdiY9yilzyM0EMeIiSjVNhLE
            mRS3BqiEKK1RKiLuetyJ4wRLC5SNEOjwRZcbGx+/olarXlev118Q55eB5Nv9CNZpnb7ZdGZGV/m/
            udwr5OMZjds1x2UHB6AjUPlllWcpHKYRlliIhjc0g9pbazfd+YF6HN0KOjJhgIlKBOUyYbmCiisE
            pSHEhEgQEBiFVQ4RT2BTTNqiffwwoz4lBIwyOOVWDatSLgebN2+5dXlx6X5glnVGX6fvATo9oyv4
            wXe8E8jsZyUQiAU8VmtEQakUoUSD6JVFQHmcyr6NjYyjJYuLt4IKi5UttMMqievhRDg8c/z6NDDv
            7wbVd4mJt0kQo0sVonIFXapiymVUFKOiAGVCvA7yawigMF4TesuGyNI+tJ9Sd5mK9iilEBH02nvy
            wvatW298/rnndnrvp4CmUspprdFa4/3qJcw5xzqt0//udJaquz/N7xqkiEsbUGC1zphaKURBQ5dZ
            iobZMzUzFsbx2xP8B2xlw80qCsMorhJFEWFcJShVieIyPiwhUYgEBq81WincqpCYB6UxzmOaC3Rm
            jxD5LgGC84DSmUYx6NF3jmq1unHLli03TU9PP+m9n2fdKbdO3+V0ekaXTJIDGMmYxasMoaZ9BmhR
            kslM0R4nBuWjjLnE0tUlOtVxZp3Q7LR1s+NuTDuLH5Co/E6Jok3KKMJy5lSLKlWCqERcqqGDCGUC
            rNKgVfY52fBUtsREIajpY7jjR4lEyOLjKgPenOTQIAg455xzbpuenv4rYBpYd8qt03c1nVGiK4EX
            SfKcubVkf1tj6JqAVEVoXwLReG1phBEvTM9tbpdq79RB6f2qFL9Om7Iy2hBWawRRhKrGqDAmjEuY
            MEaHEYjOVH91kmuvHZskGNvCHjvCcNImEH9a/4FSik6nw/j4+GXVavWaRqPxArAEpN/ul7FO6/TN
            otMyuqawyQtJnpOyA3tF2KDEbGmEuikjVtHtJqFIcBNB+cddUHqbCcoblQnQcQWT291RXEVFMT4O
            IAgz+1ibDPKqAIRMS18tktWA4NXiGQo1caOBnZlixPVQyuRSPGd3tZrtTQ571VqHW7duvbXZbH5F
            RGa998tav8iiX6d1+q6gl2CjD0BURYOyOKVpB1ncuxzXaAYVFnWFJRVtd8a8Ky2VfkwbfZ2JS9pF
            MUGpTKlUQUyMLpfRUUwQlRETglGIVpzEbXZGMuIxnTbJ9BTlpEvkLcqotWvDKhKRvqNufHz8huef
            f/4irfVR51xDKbWOlFun70o6LaN7MocagPYrjNgOIqZLE7SCCi5xodLx7YkEH0CHb5UgGg2CEB2X
            0KUKYW0IHcYEUYRXGh2WwISgNaL0Khtay+CVM0/9wNY1/3pCsVQ6dRanDlFxNt/mVx2nZfUCUuDb
            rbVs3Lhx86ZNm246ceLEE8CCc67z7X4h63QmeskB3nUaoCBjjVOQDmgGNawK+uEzryytoMxSNHxe
            M6i8x5TDH9NBdLVowRuDKtUIqlXiag2JSpi4gtcGFWiMMkCAR2PXCM+1TF785k8inQ0KEYicJVo4
            Qbw8T1kArTmTT21QoodByI4dOwqn3IxzrmuMWXfKfbNJRxBWwESDP4JLwDazf1/Ny+X/eh1BWAOz
            Rr65BNI2+LO87unOZ9snvY9Xtky9/KODD37gfXl6qEYRYMQjAmJClnWJdNtF1IlRyrB7795SpVq+
            02vz4zas/ICJSzUbZICWoBRTqmSgFuI4i30rg8px5quH6/v8WDDyCkOvlsBaBqS7aGzSJYxilAoY
            0gp3dD/jvTqhpCilABlYIE5uDogIQRCQpAnDw8NXlEqla7rd7gvA0vnnn58W+zjnOHz48Mt6JQXd
            c889iAjGGJxznK0fQET6x/R6PcJwNdqv1WrhvScMQ5xzL8IB1Gq1054/e2Yvf3u9Xj/t9ptuumnV
            d43nRDzG3pELmY3HSdMUHRqMChnvLnBF/Tk29hbQyuSO4BzDkUsCT4AoUMW8WLOwexn04YDBZPsq
            z0xpjMer51Mf3kxqexhtSLxlS2+ZXUsHmOgtrPZFkR1/KpqNR3lm5EJmSxv61xOxbPEtLl3cz0Rv
            gdR2yMzeACWaoBi30XjA5De59jn3WTpnjMy01blG6lE5sxfHFZqxWWO3/tZv/z9AX3XP7O9Egw0q
            WBWQiNAIq7homKcmZy4SkfcGG7f/aGL05UYboqhEVIox5QpBZYiwXIIwRIIQlEGCHNgiekBav1zS
            fU9/GIbgHcY5fH0B01ikbDsopdYAXl4aQ1UqleCcc8659fnnn/8KMCsiaSHxzauQry4ifP7zn9f3
            3HPPVc65qtZ6kBMV8CSnieN777nvvvvU93//918VRVFtjR9B12q1Z5vN5kIB7Fk7YV4pI59p+8uh
            REfMxuPbjlY2XQA48aKUiZKoXHqi2zrkincnSq+O+oge8L+cVhdd/Q5y5u2aiKXaBEdLG69G+Zo4
            71HaGB1O9ZpTh6SbhWaVViv3fZq52zURs6UNmyYrm3YqwQNKaUnacwcfP1eMdwOLrlEaL3l9hZft
            CvKnHVCOITspBZ6g/7i6pswRU8PVxjg2PV3V2nxf78TxH5coulsHQU1HFXQQEkQlonIFFcXoagUJ
            I3Rg8EqjpIivDwxP8Sowe34urTBiGbUpzaMHqTZbKy9UJPO6i7zk63nv2bZt240HDx68yDk3efjw
            4db27dtdFEWvCirOGMPdd9896r3/RLlc3mGtXbvLPcDnT/nylOLWW28933t/v1Jqw9oceqXUD4nI
            JyXHD6xlzO/gnPv3A/9aRKECgzi7f35x/hok6AgBPp+xmbkIiszJKmv8NkpWv6PV712vKlzS/1v5
            PwKuMmGAy074myLyy4WwOEt6B/B7xRfv/JHxsfGLw+7BxCRB9vy9B/H5vWS+qZXbyBYzzamwIsUN
            5RL8FO+z2K34dy1+JFgKRzLgifI0wgon0vjSdmp+1G7Y8l4dBhcnIpSGhkEZ4lINE5YxpTJxqQZx
            iA2yF+Bzieu/KbgT31+pvLdEOOL2Ap25aQKf4L0nCAKUUi9SXc9ESikqlcrmsbGx183Pzz8pIgtK
            qc7LUbNPRs45nHOlcrkcn2KXWzgDo1er1duADZA5EQcpSZJGoXmkafq/hUQvHo1SCmVMNmcUxyrl
            SofW4C7ZexfRKFEvQxJ6wPQnvaC57fbb1R99fXegtMIlSeYYhrpSmRR/GbN31aCUVrONRiOx1uFF
            EE+xUq2hYm6d+oqZubr6MqIkZ+ZTmKWnkuiTw1vopQnz9eXXGFX6tY4p3+N8EJswwpRj4lIVXS4T
            lSuYsIwKYpQJcSbAG4OTTGdB+Ww1zSfGin11elXaO482msAEJEmCNqdnrgAhsF2YmaTUmKFsFIFk
            K2eapgTB2aF6RYQ4jtm2bdut8/PznwZmJicnuzt27JBXQxoqpQiCoOKcKxeTac31bzrd8VprnHN/
            e/B8gxSGYbPb7RZ/95ldRIiiCGtt36733r/qjHsSDaP/9xnehfbk0k5pUGo5O4FHie/PG2NMLskF
            Eb8isdZe1xe27gDfiUa0zc6fn/v++78oVDb9Z/GyDWMsEAH3n+p+wL9IO/QiaKUINGil1t7knDFB
            /zkYAqz32LysmbJpjhzNx6QFUR43wPDGGLzPoke+0Fj60SOFVqDyByGstvE9KyZs9lteI3HO1Fhu
            1+9So1v+0uuwZqIa5VIVU4rRcZCFycKYIC7htQEV4rXJarrll8re2oDNdBYrr1IKrRTW2WxiOnvK
            fbV4DELJWpITh6naNirtkbqMwc+WyQfHMDExsatSqVzVbDZf0Fov8eoi5apA5RTbbgLOBY6eYvu1
            wB0D3/PEgj6tcu0WE9UYQ6/Xo1Qq9U2QIAjOWuM5WyreQZIkpOlpH2HmJVwBNy33TMBsaRjwfcg1
            hSmoFLFPGE3alFyCIrORl6MKiY4QP8CNA/NPKZOdXSvmSqPYMCLQ+v/pO4FFnXLxS3REMxwi0SvR
            Aec9YRCgjWEmqNFV4dpJtzz4LhSKNAhpRKMkJoR4ZeHwihzevTLeMAzpdjuEYYQosC6l5CxDaZvI
            W7RA1wTUoyqJXrm0ymHi4hXOWcIwIui18TpC+4Rgdn6hEkSl3yrH1ZqKq+iRMVRcw5TKoBU6zFYn
            0QGKFYScEjCSqVWGAa+58vnqszoWfiqb2TmL95owDPD+xTZxcbyozM6pmQC7sIBfOMFw2ibQYK2s
            PNizlFhaa6y1VCqVaMuWLbe98MILD3jv54DlV9G+rQKFu7wNHACuzL8PA3cBf3CKY9/PCt7hOLAA
            7Mq/W9YwehBk2o2I8PDDD+s777xzOI7jjcCYtdbrzB6ZByaB3iu9sbXP21q7KspwGsqfR3+SLzbD
            EnuGLzi3VO1tKdKRTBjO5PedbOwucuXiISqdOUSERlhh7/BFzMZjayRx/5w1tLoYiMXIgURHMy1T
            QeGzuQvIadBVy2GN54az6ADZQr0p/0gcxz1qtWfq7bS85rBVWAyHpRGN8GxtBzPRCBJkWdoaXwM2
            efRG0MXiveysm6lsqrR6vV5S1FSc6Cxy2fIRJnpZhGM5qPHc8A5mS6N4GAHOU0qVAKW1ng2CYKrX
            6yXbfAuiCr6bEDSPnXj91l27rnSmRHV0AoZGcEEEUYwODHjJH8ipKBvjqlVqDemB5JjMMZet2EYs
            1+w4l2eff55UVUhV0F+ZBveXfEU3WKJ2l97MYUq9ZYx2JCri4quvY/LgQWzSRXJV56U645Ik6U/K
            zZs3v+7AgQMXisjUoUOHGhdccMGrJf5GBi8JfBS4jBUG/kFOzuhjwDsHvn8m/23XwLlWic0HH3xQ
            3XPPPe8wxvzAm9/85quttRPOuY1AzRhTMMQSGfN8CvgPZIk9Bd0F/EtWEn3K+XX/1ZqxVYCPjIyM
            7CRbMIaBr9x7772/eNNNNwmcwhEohdvJV1eZt8q/o6eDN3bjsc0oP9H3QWm1RLYw/kVbzO8Ol1sL
            Ip6htE3XRMxEGzYeLU/8HjCRHzGD8h+S7Ln9osoYXYH/+0r4XeWlgvN/gPJbPaSigpKI+7+Bz7TC
            iOlSma6JiBPFcjTO0erma2bjsb8nyt8KbFWS+UpE4ei4gxkCbMU2VlAHB8rTC0LmTJWZ2mamypuY
            oRRQq7wFw/sRexX4zRAM9xU00U1gDuw8VbMH+Doi/1W86ZxvpumagEZQYaqyiaOVLXfOxht+BsVr
            ga0oFeXPcRnnJqmZT3c7C79DdeMkQUBAu3m+DgJcdRhfGSIuV9BKY7VgvT/1elcUdBh0GKyO/GT/
            lyKX3Q/s76C1zK1XXcb7X7+V/1Y/xt9M11G1DYBCe40mtzGUyhcPT0U5at0ZWsefo0RK13vK55zH
            3T/6Ezz4uc/y1S9+jlpZEWtF0knQ2mDOIOELR5ZSig0bNmzeuHHjTbOzs0957+fL5fKrhZTb0p/n
            2ez/EvB3gB1BEJCm6W0istUYc3wNc/yQUmrHgGPwT4CfHdhulVJ2aGioL8Xf9KY3bQP+k/d+M1Ac
            58mkv1JKGWA0/1wOvJnM838coF6vf7lWq9WjKLpnwPH3OuAbZAxf0G8BPz4w3hngj+++++5VN+AG
            vENaMu955stxJZQfUN39uYg+t4g5951PnlHgNQr/mrqp/q3dwxe8c0aX91/ZOILLwFdlUdwKbMqP
            OYjy/xzFP0I05Cq9KKllPj9dBW73+M2SPxqvqILmRLnG/eedSz3ewMgiYeTK/7IRVj4sykfF3JZi
            josYNDuzGzN9G1rE11EeUZ56WOGF4Us4VtpKK6hcZUz0e07z+gyokt+jVmSZlhrQNVHUUOp8lL8e
            9HtB3et1uB+taMRlHnTDQWv0/N/sViZ+Dis5SCx/jkoBaoSAEbze1Y5HfmTj//WRHw5d9zGNpFMm
            UFRGhghq1YFwBC/d1lb+rOzySBznVwN++MZtbAXe8/rr2RQKkU1XAWQcghNFmlrwQkV55MRhqt1l
            lPe4oMztb34HDG3k2lvfyOj28+h0kwz1Fq1oB2dD27Ztu1VEtgLV3bt3v1qeq/GBvz3wFPAQZA4y
            rfV4EAR3nsTH8P4gCArnyhOTk5N/Awx67xMyBl55FUqdq5QaU0o9oJT6VTJt4VLgPDLG/mes1gKu
            BH6y+FKr1VIR+btJkgz6DDTwO2RqK8AvAD81sL0DvBd4/MyPoj9P1qq8iEJAL4NeFHRz5dIZdU10
            5QlV/e+dsfOiutN5qKoILvf9Q+eh+Jn+xUQvASjJtCqPDjzayGpgzIwo6OoS9XCc7W9+u1qubf9P
            9eFtv9TTUZQxUvZRSlkyjWgJ9AKF6aQoeGCpYPpER8zFG5gtjd7RM8GXUfb1/XNlABgPeglYBFbb
            Odn55hBdz+L1o0yWJmhtu+Q3FirjP9dWOkPkiU4i5/96vFv/jfHO4m8HSXuPykE+HSs75sLRPzle
            3rpRc3j/N3pp47DDYcUiOZ4j8GBeDcU1d6Y4DamB1Amq3eKdN17NlQFUPewahzuuvJS41yT0Hq89
            nixV1ZF5OJU4dKdN+/hRStbStQHX3nwX515wCcvtFD2yiRvvuAcTlvDWZceos7exK5XKriiKrjbG
            DPPq1dQbG/i7R+aw+WtYBcm9K0lWmdtvAG7r9fpm9F9s2bIlYcXWh5MwuogcE5HXicitIvIvyNTz
            58kk9n4yFfzP14yvMAWIogil1BER+bk19veFZCr924DfWHP8zwNfPOmdK4vGYiRDc4n2OO1Bwory
            Efgg+0jw26BfC/oCskVpp6DfC3pfzhTZ6eL45k6n83cDZTJUWV4KfGCkJjshjwD3iOJcRF8I+qP5
            eQLQWvkA5YO85oJrAcROM9yMmfrkN34Ca36yl67wMDAF/GMlvJZUzqctO1jublZi7sMXCpMHdBvC
            PhJOCxcY8f+fxm4wYjEkGHEto9y/U0rdhGIHmnNFcbtoPr9yIx7wTXTabQVlnhs6j30jF9zTrY59
            GKVAUtDuBMq/dci237xr6eCvXLl08MM74uC6EP2/sD4PHZqLFeYng9t+8gMnvrbnuU9vP2fn30cV
            obJXaXoDfgAIFojHdJrccME2vu+SUWoCPnVIYHjL9dvZvf8Qzyc9Uq1xaiWOWA4Dol4XtzRLd3aG
            ivOMbdnB9bfehTMlWt0uPa04//KrOW/nLo488zilMCHUwVnHRZ1z0cTExK1TU1MPaK3nvPevhvd9
            y8DfhVf2AaAFVEWEJElu73a75aGhocJc+LFidltrO3Ec/3m+EAxiWvXatyUiR4AjxfdTOCc3nmqg
            1lq01iilPqaU+s8i8qGBzT+Zj2sQMvgfgd898yPQJKpEI9zAbLiJrq6UV7R6DcLHIp98YzhtUrIW
            lG92TfCn9XD4wUQH95MtNIj32LD0rsXq+Ee89nR1FANRltGczbXI2d1Dafv7S84u5IVRmlo8XRNR
            D4dLPR31F3AldJVkyMTQQanphuve/1KWBeIAQ+yTx6tJ862R7x0z3tANY+aGhkGXEedFixqE3rYL
            CawEuib6ZVH+3DygjhJmQ0neNtJLHgrE4lQWPWgEww9aHf0b4O7MoQ2RS1qjSbuH99SjGl0Tfgjr
            NEFejMW7n0XpzxdagnJCp95IKIX/IjDxD1mVlWYRkbuD4cYS6VNPfFxffeOHbGnYUA3xBrL4oeZl
            CMVVVBxuCCnZDltD4b03X5qJuKRHpAJwsNPAe19/Lb9571dpGQOiCMMIrzX0uoy6LvUjB4gSi+iA
            1912N1RGqacOTIzk+PY3vPntHD16GNdZIlKKUzrOc/VKaekDbRYXF7HWMjQ09DrgwqKmHK8gVSqP
            nU8UsW1WGP154FHgdoAoii6MougG4Ctkqv5bgcKD/eVOp7Pv/vvvV3ffffegRO8qpXqFfV7YywU+
            fk0UYhy4noxZ39RnsWy/uvd+1f75uf7PKIputdZeAWCM0c65QZX7C/V6/R+Xy2XCMGTgHlfYO68n
            KGjqZhN7yzdyfHgzrVK7hOqsmHxCZzhtcsXyYSZ6SyjJseSjO47OxWO/D/xGAYFdNNE1z4xeMAHM
            LkU1k0HrVhSbcmvp1y+pH17Ybpv97EWvPLOlUfYOXxTOlkZNMTeVNl2tgm6Ql0ErGfNmFZidmS1u
            QOgOJ92/e1n98LHx3hKp7TEzsZW5DedAtAGaQeQT1cePAK16Fj0A9DlLYe2dRREVlRVU+fmxXvuh
            SxsvMNFdwqGYi8fYM7yT+XhDOQPMA2hGk279qqWDXSXwzOgFW4+H0e2Y3Cb3upk5yvwdy1EUPL1h
            B8Z7urqSamGL99JVmrJkfpCdwbhL4Ojhhxcnjz40Or7lDc5ZyB1UBXT1lQj4InAfBRpdb/KWmy7j
            qqHs/RYA/NiAdnDTjirX79jCV6fmSUtDWMAllqqGcGEemZsmDGMuv/o6zr3kCloWrFYZmMKDiKI8
            to1rb72bRz79MSJeWrhNRKjX66RpijGGUqm0dXh4+HWNRuMpEZkHui/3/nPmGR34aWng78+RM3pO
            N5Ex+huB7ZCp0mma/inALbfcYlgdj7fWWldAN5VSJEmSJewkyVAYhjeTxeBvAS4ms7FPhkhqnGL4
            C+12+8PGmE/GcRyvQeU9C3ywVCp1Cm/+SQE5eXNMJdDTEfPlcaZsSSOdCHEFg3hEt0rOMtFbYnt7
            pi9gYr8dlP8byb31Ck+io+p8XNoKejZLIfP9xU9jFoe0fug8abOlM7PC6PnWg1W7SgsS5TtdE3Xn
            o1EUkBr9Jo3N9s+O/avIJ49NdOts78xgAkPSngOzjfPvep069JePVZXofjchlG4lusRcHAH6FtAb
            i+cQ+mT3WNr96LbOCbZ1ZpjoLYHP4NoHqgnga0qkH6IuuaS9qbsAQOjPvRrR4yibjd7rGvBxAGsi
            Fk1Wwi1weXLN4GtQPgjKnTbv+3sfav3xN/b9+dDFV77BhxVUEPUXqDOzSVEYcgV9tBbTESCobpPL
            tm3gnmu2kMUjBAkCihfonGPYGN59y1Uc/NO/ZtqV8EEZgyVMuvhjRwjrdcqjG7nqljfiopius+Tq
            R+5WFoSIS666iclnvsH8oX2U4jDPKsqZ3luUWlHpvfd0Oh1arQx7qZQijmNGRkZubzabnxaRE7wC
            Rq/X6+Xh4eHRKIro9XpoXTiZAHhwze63A/8G+FvFD865ae/9XxtjWF5eVuVyeVBtVmtfURzHE865
            f1Sr1X7cWrtN5EWgyDkyxr5g4LeFU43//vvv/8Ldd9/9sHPutjUawieAyTiOWasNrCVVhFd1Fxec
            gDAJkW4tM6f7UYFusSAM4rUz53Awh/IIHpXNFw2EubQKEB8U11GBnlteXjrqnMdZCybqW/i5dlFG
            soL/SnkE7HJUSp8d2QF46lF0AcqifVY/QbT/vMsdbUpAKU1VIkaXDIf+6olYWVMx4nBic/hI0M40
            D48SrsnSuz0QMJLUH7q08UJ3W2eGqqvjlaVwnGVVm/yYRijUfE3a9MoXDUfPyRm8eOOTwEfztD5V
            xMAzv1Ze5yFz+oV4DgcVo2j1OrBv96e6N77+l+JSZZsKIlRw9plbq/PHswFm0RzBWstN199AFVCJ
            wxiFtY7QgHhBiSe1hqur8NbXXs1/+9ozBDomUhD2GvSOH2HIwM0330x1bIKG8zjRCDlAJ38RWhtK
            5SFec8tdfHrqKDEC2HwhUhTmZVE4MkkS6vV6f6IWaK7h4eFd8/PzV7bb7QNAnZeJlJuYmChZayvW
            2gKZNojmfhQ4OMB0lwE3kknggv5KRE5orRkaGjJk4JuCXBAE3ntfMNvlxpi/0FpfliTJIFa/QRYe
            +2vgj4F/Agza3nOnGv8b3/jGDymlbjtJ0szPAH8GPF78HkURaxyK+SQuPOIJlgblDeNBp+0r2hi8
            A5XlYK5CDq623/VEEYbL8BG659Hz+TwroXxYrNzOuvmRkVHrZw4ThiGrgYAeoAR5Zw8BJc6lKnLz
            cQkgTHRvuC+mRINkYcdiTN4JKhWGk5AlzwaDbB/oYSCAG4hATejiHgAIjmVhNc2gg9Hn4Tjw2/vG
            rvJ45RuiQJRC0EEh1HKJvyfw9ueG0jYlZ7NwtM9TWQv/Vlb5ISvTvtBepkGT1//Qmw88sn/3py7Y
            tP2nbFTBxDFd586Y7Cm5w8zk6ageuxJrBLzWeDFYFfLUs0d527ZziYzBe0cYCIG3+BwqaERIneJN
            12zjawePs2e2Ti0MsfPHkcYCxraQtEPSbdCyES4qZ5O5SIUVj8PTtcL4RVez8zW3ceSRrxCkKaZU
            Au8JAfEOJ5maubCQCbNiApdKJdI0pVQqRcPDw7e12+0HgblarZaeLMnlTPnYaZpWRWR44Nilgc0t
            EXlYRC7Icek7gF9jJYyFiPxpgZEfGhrSSZIMik3X7XZ9qVRicnIy2rZt2x8Alw148o8ppX4D+HiS
            JMcKxFoQBOOFPV34DYrxDUpnEXmjMeY/FHb3GoldA347SZI3aa27Sim63e6LEoGKPHIE0EIQaLrd
            JMQEQ148KEEy19JF4Pe63Cuv8+tFSqOUulkENAaXWoIwPqajeNqlCYitKvFIAawSvww57sALepU7
            1uO1rwzWFNQ+kEAQnKLTWBY2lBwKHArtBRTjWrLMN680XnxeqyBFRXKtg41ZVeQAr72Isn2BIGjv
            FCiv0RKwHIxu3Dt0OcvhKJfXn6OSLmQKjVYopcHITQWSV7Qm1XrRowl0SCmqLuGj/EF60Pb8kbRZ
            3rV0sDPRy6aUyjH0/UVSeYaHa4iz6F5q0c4xJG3S3Y99lNaSJWmRJl2MWY3DfRGT52vG2tJNKv8U
            9plDCKISTz73Ag8/16BjwBuDTx1eHFo8GoURT0Vlsai33ngtY0mdansBO3OUWiAEYvnGQ1+mPjdN
            FGQ2qbNZgQhrHc4KvV6PnhfaEnD1TXdQ3jCBCsogGqNzNT+fuHNz8y++pwFnUuGUA4ZarZYZdHoN
            Or9ORyJSAYYH9h1kdMIwvNcYQy7xDRmApaAnut3uVwpn4dLSUoXV4TVVMOU555zzRqXU6wa2TQK3
            ee9/x1p7zBgzWLRiaM0w+6ZJ0cjCGHORMea/Q2Zp5XSY1eG824wxvzyAuDslebWqxFdIXzPpYzDu
            THKs+1RlI1PlTcyGI0zOLwaSpD8c5vMliCIinzw80pjubkyWKblkZI0fqZGp6S8aQXH97LorGkM3
            9q63oTXHjrLY0KfHIBhIIuHNqQ6Yj0eZKm9iqryJ+XiUNMOZfyhbYIpFQ1ug3ceVKH8Ysnx4n3nX
            3zgbj9WOlbZyrLyFqUp2vtl4lJ4ObgVuBT8gKM2yI0vmaTfqzwQKZ7zGeA3CxUq4PpsEA2nhKgtj
            KvIEIC9Z0lioI4xRqG4Dpg48PHfkuYc3jo+/wZIl9pwpI1vySi4udwYWHVE9AU5pjPd4b9FGs2wd
            H39sLxdfciPnABvCCCcJmf8hWxicz1Txm7YZpi4/lwcfepATMweJyLSLzvI8T37ty1z/pgmCKKQ7
            4CDyAt6mxIGh52BoZJTLXncbD3z2fzFuhEgLvY4AmvmFBRKXslZIO+f6kmt4eHhbrVa7sdlsPi0i
            C6zBMb8UEpEhlcMTc2ZYpQIkSfKI1rqtlKqsUbcBPlYqlWyRiFIqlV4UTvPeF/jybZmq2l+Yvwy8
            MOggG9g2WMMJsshCMV7a7fbQyMjIH3a73XMGim88TrYI/b9kIBwAgiD4Fe/9l5VSXzqLPINhIMyL
            /WZMInyoEdS+8OzwBZ89WE0BQ0+XQOLfUEFwk3UaTIhXMOSW/vTK1iTeO/YOnTc2G4+ueiRnuPbK
            IqfAa/EjvuMvab2ANB3NjTsfnNflHyoWDyW8px5WPv3s8I7/Efnt2TvTAfWw8ivgf2jVhRWCYMmr
            ISH6q5k0zxY68Jeh+NVGFP3i3pEdRH4zoqCngyvqYe2/AoEUtRwEjKdpdIAJNONj1b1zafuRgPCm
            zFHoVTOs/Pqzwxe99UDVnlytVJ477rhNPfbYo5LlOKQJJXG8+Ud/pLHw/HMfpdeB1JJ0z5zzoGSw
            CkiOe8egBULvMd4TqjxMNDzC7oU2n/jGPKmGnjH9eHk/Z1iEkoKahbfdeCXx8jRBax7X62CUECvH
            ob1Pc/zAfnTSRvs05/AVCZs6Dyag0fNsvngXWy/ZRS/3yovSzOVMfjIarCkXBAFjY2O3AlvDMKwW
            KvTg5yXQjjXSbmnwy+c+97k93vvdxpi1TN4FPu6c64fLgiAoswbEU6DpjDFH1iSR3ABsLRauL3zh
            C2p0dNTkxw9i74GVLHARoVar/a619pYBpN4s8EHgBPCPGMi0E5EI+EiOxnspzwMy+G3/5Dqzbatd
            E318Phr7o2PlzT91rLzpF+bCkQddVPr5fK8MDi323jffcctnNnbnGGvOEvlkdM25m2e49sSqb+JV
            a+mEGu3Ns8U3KTn7p8CJFaeyVz0T/OFsafQvpiob//5UZeP/MVsa/VzPBL+O8p7cd+Mz55cHksha
            NnaX2NhbeKDku49moKFMymv8LyRaf3U2Hv35qcqmnz5W3vif50qjDyQmuJh82St4QQseL0RJQmXu
            sNucLv2HyHdz+x16QXzrbDx6/1Rl49+aqmzcPlXZWJqqbByaqmy8Zqqy8d1T5U3/5Y8eeOInp8qb
            0eIt2hiUD9ESwN59n1memZuWxBEHYT/PV+fppIOkyEAGoVtB0fXLMLkUk3YIUovxkFhLEkYktRG+
            +PRenq1nOqCYKHfYrZBxjpoIW8uGH7zltURJE50X7gsRAt/jkS99jmRxjtBbxFm8TRFnETTWQ7eX
            0vUKGRrjihtvxZeGmau3ODE7j/cZ46gz5L5baxkeHr6qVCpdlabpcL1eDzl72ryGAVatvnfddZdo
            rb9S2M8DC8h9IvJs8UwBvPcl1jB6sTBprf/GGPNEoXprrS/RWt8fhuG/11r/2t133/2njUbjb2ut
            LTBahMNyEyQZcLb9mvf+fcV3n6kBP6WUejK/3kER+bk15svlwK+/lIfhs2OGETEiKvco6694xX1A
            GfT7tOj/rIV/q7S8XuX52mSZjSeU1h/+wpe+KF6EzNJheO1rO8MQNq36Jk7ickmU0SQZ/Poo8CuF
            gywDfHkN/h3gPwL+t8DfnZdY+a8QPAlB7rfD4aU70mtyxdLzXLH0fDrkln4RZXsoixZfmDA3a+Hf
            aeF3gZ/KEmX8w+DvLZx0osFp11NKqPUaXF4/yiWNwx+NXf23XKwQkz0XUf46lP8TlH8W5Y+g/EGU
            fwTl/xzlfwKt0xW3X/YKCL3ltW9/6/6Z5579DEmPtJeVUBYvq4ruFaSlKNBTfMh6o7mEUq/OLZdf
            yLU7thJ2WxgliDb4IGLZwV9+7TGmAYtBfO4YIpNeygvaOwLn+MF73sQN115LkmQSOLU9jPe45QX2
            Pfo1Am8Rm9DrdXDOYp3FeiF1np6D5V5Kbds5bL7wMqbmG8gAn/QTbQaoWKgK5orjuDQ8PHwLmeug
            fJJSUKclpdTONSCUVatLrhp/2VpLIb1zL/pfFGr3wPFDrFa7tfde+awXfM9a+w9EZHqACS8xxvyc
            9/5XgfeIyNNJktS89xvXlE0qHsTPAL+6ig9Efgn4X2tu62PAf1rz24eAv/sSH8sA9l8j6IYo/SOC
            /tLaHYuSkCj/eNl13zLRW9q7qbOUoecy9XjzmkOGOT2t3b8LdL2CyFvGewuM9+b/IJTkb3vt5/rJ
            Ndk4i48jW9h+GagN5NQr0KrkLBPdDA+wvX38/vFk4T2Rs5PZ3WaS3etMKkvmJb9PCe8k05xYCVcb
            dBZPZ7xXZ1tnjvfevOvnR7oLP0uveSKbYAMpuZm2Ms6AH0eF8R6vQwJwWUUPBZHvMuEi5KnHPtq+
            5Lq/E5fG8MaDyZl9jQD0g97LAmmmFJG37Kgo3n3jBmaX4NhzT2K9ousVURjjVcwDUzNcuH+W9108
            QcVm7Y0FwAlWZcuGKIhKJX7sxz7Iv9r3q9ikS1CqIl6h0oTD+55m8yVXEE2cgwhZ8osyeCWZpNCe
            btqjmXbYcvGVjDy5Gzt/BCPkq+uZZ6QxhvHx8ZtnZmYuAo71er1mEARng5T7H8BXyVQ8DXx97Q71
            ev2+oaGhtwJBLmVFRL4I/eyzYpF4niz90mSTirrWulNsd849qLW+gayO2a3A9vy4IyLy+fvuu++J
            W2+9NRKRHxWRIN8mwL58KPuAdw2MtVWv1+8fGRmhwAEM2Oz/lAzwU0iAcGWinpEeyu/DZbj04DgS
            zAM/AN23gH+bJ9opIGi7G+FzWuxnR9N65/LGAba35xhJE2azkNjv5uOwZNrO0TNc+z+RLVwOvEH8
            fJZIpRlK21xRP8CQq/PM8M4/Xipt+JJkCMU3gd6G6DbwEMr/peAfRQjB/yxCLXfvp1qKUGV2vsvq
            hxiy3U/uGbr04W5c+gEtfJ/GX+gUFsXjKD6P6HtBp0r8uVJULPcRyke9QsJrPMNpk6W//hO5srTh
            P+4dOe/PZl3yJoivEvR1ZOHJHM7HUeAF0M+ZaOgpq8sEg1VhQkkpJw2YOvjg7OShr1WqIzeXxzcS
            lquoQGfe8VVqqM+dDLqfiirOEqcN3nHLDVyqYecYHLz6Ev7kiX2EGzYivkfHeUxlA/c+vo9btkwQ
            RxrjQUmCEo0IWJ2NLO1aLrv0Cm678w7u/cxncT1LoBUlE+B6LZ566G94/fe/nbAyTDNxeAHJPazi
            YbHRJkAYG93KZa+7lcfu/SjaJxmz41dn652EkiRhaGho2/Dw8A2tVutp59yCtbb7UqvZKKWeAJ44
            lVc67+7aAT59Km1hjcf+U4PbwjCk8Hrnn0myTLPfgRVnXRRF3HbbbSilusCns0hF9vsAfWHttScm
            Jij2LYpa5LRIBpp5yVRyCRPdRYBJUUxCPmcI8ESA7xq6H9e4j3uJM2+1TgBH6AybuvNs70wz0auj
            fZSdrzf/JMo+mS3amY9iortEdJIa7fn1HwZW2nb5DI1XcgmxT5jotfHKsxTWKPnkWCDy+8Dviwr7
            c0XjUZICpKLCLxS9BrWAdp6Jbn3N9T0of0KM/wNn/B/gEzZ0M3jucljD6giEKugLkX5mG6C7/eOB
            sk8J6zN0e22WdTQbRcN/hIT9RqcvmjdoSjpFSxN15dVXZRMyB5CIN8zEQ8yOnv/h6u1v+Q9qZDM+
            LhOWQ6rlmMC6lTI/eKySvCiEQdsE02tx9yU7+PlbL2Comw3xeAC/8vGv8UwzhVoFFcbgHNVWkx84
            dysfvPMiRjoQJh0MBqs1NldtNJ5yKaKxsMA/+ce/xMz08cxO1+BUTFuVuOi1t3DODbey5LIe6r3U
            0kk6pImggxKgCcRSdk2e+MJHmXzyIcYiTyQe608fFipU3BMnTjxy5MiRX1ZKPem9X7joooskSRKO
            Hj2TAFmnPp2q4UF/YhcOsOI3WOVEdwmkTfB5V55+I4iX2JDhTA0Xiv11kO2n1wYnTtVAYU3paWez
            RhRhhUt/5Tf1Ve/8Mfn0g49IpxTCBgXJMq87Okmp7dldO5/58gYQ/YOgP0nfX+XZ3p7+/ql/+sF7
            VTurC6KUyouvaijVQIWDYcCTPfD+mM3WTSsmiwICrfBRxNLU8QU5/6J368pYrYehZ1N6aYpBZf+Z
            rLqm1xpRWeE731rm3NDxE3e9hp0hVHop2lrK5QBXGWfvwQN0lULCGO81yinqSw02jZ3DORsUgVU4
            NE4JTkmevSYInlq1RrVS4esPfY0wCjBaEYaGtNej0WoxsmkbLgxYanfppCnWerzXWKdxDlJxWJ8y
            NjbCkQP7kU4LIzYDKpyGBgotjs3Pzz/lnDustW7WajXvnKPRaLBOL5HEgetA2lrzaULayP8d/K25
            ej/XzeGhAAJiT34+182u9VKvv3Z/8WC7pxhn8xS/D56vk50vGqb81vdf8dB04yPOqCrKzZ3zpjc0
            6vv3s71eJ0wUM/EoHVO9C8XvotRofzFRrl5zy7/RvO8TCyptgZKVtGsvKNeDpH2SsZzsuTYw505M
            oEUgL3UbGkMPQ3N867xN9a7ytvOvs1HWnMF5sA6s5BEtpQmCKAttOc9wr80Hb7qMO7YNEbY9odOE
            QUhqYcumkKmZOY4utHBhOavHrRSJNzSWF9l17iYCLD3vSHFYcTjvsSK0Wh1S79hx/vkcnZxk/3PP
            EUflPrxxdm6OpcVFJjZtpWGhKwYn4DzgPeI9ThwijshEGCUsTB2iEhpE3Gnx/AWjl0qlsNVqtbvd
            7je898tDQ0NJjj//drPPOn2nUlildsdba41o6N8j/j2Y4EP1vUd+CF+6pWWju5ZK429fNiP/xOng
            l9BuQ57GmnVh0fbTQ2n995pf/ATKFnkY+Xkl//ssMkvNtk2bMjx8zujOZg4tUSHtI1NJfMmVP5qE
            ZUUQ4HPAvBMhdQ6L0Ol0CBEi2+XaTTV+8pZL2JBYQgdJaklTTxgblIZNW7fz8HMv0HKgTYQ2EVYb
            lhbnGY4jtm8exTmPiMWRlcBVyqBMROIcTim2bdvOI48+RqfdplFv0O606HQ6LM0vUK3WqE1sJ83z
            HMRlT0PE40UhokitY+umzcweP0ynsYRhLUxyNRVxaOccYRgOzc7Ofh040Wg0WsPDw3ImCOw6fQ9T
            WGX4+94RNOORn8HoMt00gmi7subarqnc2A6r1zttzgFRKAciGFFErjs1nC7+nS3d+dmZL30K0l6e
            U5uf92Uwuk61x+kVC0MJlMQz0mvB5IEHO9OTj5i0DS5FmQC0wamABEMnFXpO0Vxeotye4R23XEdZ
            QItFjMOUNaqscd4Sec/5NcVbbr6Rik0wPgO2pMZTDw2feup5dteh4RXWO9CK1HnaiaOTenqiaVjH
            xPkXcOPtd3B4eoblZps0TQhw1ALPC08+Sro4Q+RSlPU4Dy5JSdOUNHX0UsHqMnUXsOOqG+lGQ6Si
            +2WQB+PdRa27wdh2tVo9t1Kp3KCUGhGR6Du4C8o6fYeQt+lyoPVXSWigywSYrB+cCvMMMwsmzZ1k
            JIL79JBt3LNr6dCeXUsHMvU8J/ErloucTdwHMJu2TGRtXxQYZTBaI+IJALXlnF693hkpXbjzHkoV
            rNKZA6DPEB5JE6ppm3e/9nJ+8JIxKi5LVMksa1kpTaUgNRoZL/HsdIfJpSaUyiTi0WFA4qGz3OD6
            8zYTJD0a3Q6pKKxTpAJdPNZ5uknChRfu5OnHn2JhbpYw1GglKO9JrCdxmokt52BVhBWFzz2xPkuW
            xVqPUlAdrpK0WzSOH6FsWOtRXlk81+j1SqmgXq9/XSm1sLy8/LLTV9fpe4B0yBV3vdkvz8//+UaT
            /rcNtv2XG5L2/SNJ66Eh254ass1Hq3b5yZG08cUNvd7vjCTNfz6ctj6yuTM3c2HzCBPdJb7+tYde
            laEEBfhf5zDW1Ht0ECF0MboHh/Z9qj1/4pfjanWjUiZP5c3SQrVzxN5xzbYt/PD1OxkBdOqyDpAq
            CxWJaLTKkHMK6ApMLcyzmFjodbG2RwykzvI3jzzE5XaJu19zBQkxvdQTOodXLsPS46lEIZVyxDvf
            815++1//S5xNCXTOkGnKsb1PsnnrDsx5u/IuMi5vNpE3NhCHR7OUhpy367V0Du/BNmfQocZnOUun
            fWDDw8PXGGN2OecOklWLOTsEzTp975Btc+nyQXaYyKq6PR4gx3WPB8nrIXrlcSoDgmkf5RVrPaHr
            MGTbA12OXjmZLZsz6K/KaxhYFF45TKDpeKExvbDA5m2Xh+Obr7VRlIUmcgMhtpaxbp1/8LbbuX4U
            omY3g/uKz711eQcJZXAmom7gI5/bzaPH5uiZCg6DTRyh8kS9BrO7H+PYQw9wxw03kMQlWmkmjZ0I
            CY5UhE6S0rOWc7afy9zsDPv37SHMcOBZ6540ZWm5xdg552NVgPOCE0G8IE7AplgvdL1QigKqpJyY
            OgLOAT5LclGSZfIreVGB/yiKgl6v12y3299QStVF5NVt5r1O30XkeOONr2EoWWYkWWY4aTKatBi2
            LYbS4t8GI2mH4bTDcNJi2Dap2A6BWBSKhx7++isfBhA8+fReYKXanxuMFZoSd33gQ/KFBx/+qLnk
            8g+o6rASZbPsYaspuR7veu3FvGFrZmaYQIPt5kkuWQzSeE8aaDoRfGH/El/ed5h2PIZTAWINylTw
            zTnGbJ3e4gnqRw9w7yc/zh1/+/0k3vclsRePUxodhHScp2std73rh3numadozkxilMM5S2Qi2gvH
            OP7sE5x79U0s+gBPiM4ADti8x7RWmnZPKG+/hMr5J2gcepKKSO7zGCxfvSplEecco6OjN83MzFxA
            Vlm1xSuoKbdOp6crrrjitNvXAH5eRGtBSEqpfo+6Agg0WIZrbavsvBx3v4/doB+n6NtXdPs5WZvt
            z376UwTGEAUG62wfQQrZpClq9itlsKklCHUfCu2c48orr1xVi+9UfqECuRiGYdHYs0iEyv4uBFa/
            MUb/UA3eMpK24fihB5vHJh/Rtof2NosR5s6qSy++gCHyopQ2ybzYRe0grdBBRBIG7OnAf7//IRp5
            //WsHC7ghaE4Ipk8RLx4gg1GuP/zf83B/c8SxQE9L3RF6HpF4oVmkjnmWh42bjuHq669mqTby2q5
            G0U5MpRMyvTzT9CaPohJLTiH9w7rHVZ89vEO6zNATVyqYUxEuKqNll8pgTRAeXbXubVa7QayLLDT
            z7R1+rbSybINiypCYRiuZDym6Um75xY9/QqGhz7cuP9bkel4MiqFETr3DSG6DwkalAxJkiDiEFy/
            jVXBpEXDReCkLa6K8SdJQpGmnBcX6S9owBkMUoFQee58zzuWOfjcx6K0i04t2goaS6KFjz/4GEeA
            noYgWOEKry1OWxIlpAb+7BsH2d0RuibnizybR4tHdzs0D+2n0q5TNorm0jyf+Ys/oVufJ7GOrlV0
            nNB2QqfXw6YO74QX9h/g8ce+QWgUzjus94jLWj1FyRJTzz2O7TVwzpNaReoMiff0vCV1AqmjMzPF
            wuHnCJwDp8j7teadNE5OcRwzPj5+G1kZ5yrr9B1LxphVn4LyLrX9dlwFU52MrLX9T8HgBRV/Fz3n
            1n4KyVp8TjXGwexFWGHgQosoGHgthWHYP3cxhkIjSNO0X2zkDJWiPNo7Qm/h0L5PSn1hLkgteIsX
            S89onp5e5BPfOIFosF7nsfbMqZBqaMUhXzme8IlHn6RZqmEHO0BiKUuKnz+Omz1BTXm861Ktlnj2
            sUf5xt/8DeI81un+p5dCkliMFT73yb9i7vhx4jhGvBCGMdb20FhK9FicfIHG8YOYtIN1jtRJFqd3
            HmUt2nWZ2v80ulunZDRGF5DCPFVwTfUcoF9Xbmxs7Cqt9S6ybKlXq9HDOp09jQE7yarcTqzdmEvy
            jXkW4c5utzv+zDPP9N9qweRa69q+fftexA/79u3T5XK5eiqVudfrxUqpsN1uj3vvL/be7/Teb/Pe
            n+u936mUulgpNbJ7927V6XRqa49/9tlntVJqSxiGO0XkwiAIRgpV3VqL957du3crrfXo3r17X8Tp
            zjl2794dRFFUKTIfd+/ebYBzoyjaGQTBRd1ut1RUXB4oJ7q6GfyuK3fRVYqRa66ZP3rgwDXh1vOu
            VqUqLtB4ZXASMDs5yesv3smWWoRPZSVltTLEAYHf+uTXOJB4UmVAx0U/bCJv2WRbtJ55lKHFQ1TT
            JkEc5tU+HZNHjnHJ1Tfiw5h2mpAIiI4JEA488hBf+cTHGTIOIxaNQbxHtMKorImj1ob5xTobt5yD
            jTKTwaU9IoRYJbSOPU/ryG7Ktp355SVHJeQdQBRZvvQgFSt1GIZRkiSNVqv1OFmO+bpT7ptAExMT
            p9wWx/HfMca8SylVFpGLRcR5748WNrdz7hpjzD9QSl2fF8a4KIqit27duvWmJEmeC4Kgaa3l4MGD
            Zvv27R+ZmJj4mrV2sHgnW7duPScMw3+zZ8+eT05MTLzIFCiXy38PmIjjWLTWt2mtN2mt/6HW+iqt
            tfbeX6aUmt6yZcvWOI7/qff+00X/8kqlcue2bdt+yhhzBTBmjLlcKfWOMAwvt9Y+EwRBorVm27Zt
            o0EQPLhp0yantX60AHENlBG7Hni/9/4rSqkNmzdv/vUwDHcopTZ57y80xhw+oyTSWlPGM9xrwsF9
            fxpcfdP7elEVoghKZXpec6Ld5U++/DgXvP06YhMTO4+JIprAJx6d4/ETLfzIcMbgTvertgaSoGeO
            YmaPYPKcdZwF7yiL0Jib5cv3foq7fuQDtBOHdZpY91CdBl/65F8Spl208auB/cpnuXoCobfoxjTz
            LzzByJW3084fjHddgmSJpal9qLSNyRtGZu2fgLyyrM87ZqylQr2q1WqvnZmZOY/MKdfkrLBK6/Ry
            qFhoy+Xy1UqpNwA/zUCF3qKRRJIkN8Zx/IvW2n8FPFlszxGOt0RR9JvW2v9Taz21c+dOo5SqOffi
            skOVSqXqvdeXXnqpFKr+GqqRmdxPDlxnJ1mF38FMw5uAXlEfMAzDH07T9G0i8s9E5EhRBGT37t36
            2muvfUe5XP53J06c+PCGDRs6ZObhV4HXGGOucs49vaZZRr+pRhRFf19EnvTe/0GhwsMZVXcIdEDF
            a0abLXh27wOto4ceC22C8QrtFNpE9OIanz90gk8+n2DKClRIGgY8M+/4q0eeph4Ok6oS5L22NFn3
            ydgnqPkDlJvHGQ5NVi0zdRiXtZ0NA8eeRx7g6ce+xkJPmG1bOo06X/n0J1g8coCRQKHyEtNFcX/J
            Y5Q27yJbo8Py4d20pg9S1hniLtDQnn6BZPZQ5q3X9M0NsgAj5PW0i+qiBRXSAqBcLm8kK9W87pT7
            FlFeuBJgg/e+x5oy3Hmd/lhr/Q/TNP1VEXly0GbOm1s8ICJ/HUXRLwIkSaKste2TMbr3vuKcS+CU
            7a0UWc/7VcPkxfUFa+RFOI0x5yil3p2m6YfTND1SRAC89+zatctbaz/uvT86MTHx0/mxERlm47e0
            1j9fr9dLRYnvnMLi3FrrcWBmcLtSZ0KHkHVK0TahJpa7f+RdS/7Avj9XnQaB9/ii6kscMx8P82cP
            PsbRLrRKIdPAH3/xIY73PD6u4qxCYzDiCbwn8JayTagffo5yt4GkPaIoykJf+UIVIERJna994bPM
            n5imPj/H/se/zjMPP0BsJI99n4Tyxo6iIAohdB1O7H8atXScOG0izXkWDu6lKh2M2DWIuMwneirR
            XHhZ8xXYkhUb3MDq6qzr9E2iwvP8xBNPPGiMKQPvHtyeS/s3aK1PKKX2FBO++BThtjAMPy8iF1Yq
            lUocx6HW2rVarReBn6y1FcAW7/0kFJM1zhykkJMDqdLc7Pv+NE0fFpGFgXJeg9WFUEp9ZqCqb4Vs
            8djT6/W+OjY29uE1NQtjcm3Se//nwAdzE6K/zxkZferYUSanjnD06EGmpw7C4f2fkubirE57aCUI
            KalPaauA5xfbfPThoywZ+It9KV+dbOKiCiqr94U4i8IS0GWIlHTuOLq+REU7QqNIXC+TrkWtdueo
            iCU5cZQXHn0AszjNvge/SClpoZTCDuQVZ4Uzs0KVRWkrlEdMjFKKoDlJ4/mH2MQCzUNP4eszlLBZ
            40dUXhPPsLa4vri8FHUe5igw8SLC8vLydH75EqsbD750Umf4fKfTN3n8rVbrRZ96vc6FF15ou93u
            z5FV0/nporhm7k2/RkSeLrzVgx/nHEmS8Oijj84rpRa11lu995H33k1NTaVRFDH4McZUgXYURVQq
            FeI4XvXRWg+JSLfX69FqZQlWZJOn1+l0CkcfwJCIdPLxXRYEweNrrzX4AY4qpbxSKlJKRSLSyRe5
            3w+C4Lw4ju8sPPVk2kKSRxAeAv6r1vr/NcZcUSwgZ2T0ftRPZTXlWDixt3fwuc8FkqB8lvqZlc2F
            Vhjxmaef489egP/51SdYimokuZe9n0rrUwKxDCctkqOHKLkkqxKLyyGr2RWVVhiV1XrX3QYzux/j
            uQe+QDI9RUUsSjzWJf1V1ve7UxR1rvPYo0CgoezauLnDzD37GJ0Th6goB86u1L8uqF+PPqM4ztqR
            57Zd4Yij0Wg0jh07tpdMdezBGStjr9OrQIWUstbS6XQWkyT5cRHZAfw7rbMOLMaYmjGmdbLji0Ii
            xhjlnOt57zvGmGoURb0rr7zyZO8w4sWq+SBVeLEj9mRS3rAi5csMVN49BSky9T8hs9E7+b3R7Xb/
            rYj8RJqmRX28yuAYvfefBf6Z1vpfBUHwVq31S2H0lSZ5kbdccuP1wr7df+6Wl/CFSaOygu5dYzhi
            Qj5y71d4rt6iFUYkhqwfdr8oniZUEM4cg8kXKOfebskrvSitUDr3/GuFF0VZoDI/Q/vZ3VRtF20t
            kQhmMBn/pOSxYjHGE2uFSTosHdlH1KtneehRvNJVdbAI/sD3wn7KHyDee5menp7av3//l4Fpsjpp
            C7zMlk3rdPZUOMTyuHFirf0V59wh4Fdyu/QwcMlgvLugIq59+eWXbwqCoJQkybEoiqrWWnuy/clC
            p6crIV3mxUytefF8qLFit88Al5zhNs8f2L9c/J3PxQNRFH1maGjol/PtqxgdwHv/FPBTxph3hGF4
            80tgdChwPIF4RlwCB/Z9pX1i8vHYO8xKR0y8goaJaUVViKqICkBr+p0rABSUJMUfO0C8PENo01VM
            PkjF76FR1CRhyHfzRg55P+iXUEdc510rjPaYPL6ukyZGCc6eGblaAC263W5nenp6//79+79w8ODB
            +0TkgIgcICuoOMN6eO1bQsWiW4BLCkSac+73rbW7kiQZcs7d55x7TZIkazvS9NX7OI7f6r1/JFfv
            S5z6/bVYWyJ6hTaTOWKPr/n9ZDZ6aeC3+4B7znCrbyEregmZRO8vJkopOp3O/xSRGLiTTFt4UXMR
            7/0c8JdKqbe/ZNVdlEdhKdkOl7/+hiUOPf9xaS4QuDRPiCELn0mEs0GWzuoHfifDvcdpQtyqY48f
            YJOxhGL7UnyQ+maeZD20MCmiE0TZflM6j+t7xdd6xyGTyMZnH+sdynhEUqJwRWIPLhYFUxd4aO+9
            zM3NzRw8ePDre/fu/czk5OSXm83mM8B+pdQ3gIeB58ji6OuhtW8BDXjdS0ePHjVaa8IwJAzDc40x
            qtlsWmvtlDHmr4Ig+NdRFG2Elfbde/fuVaVS6UdE5IaZmZn/kofMYqVUWiDLYGUhEZEHjDHnWGvv
            OEkDj38IPNLr9ZoF1n3fvn3KGBOKSG9Nf7uyUqqQ8g+QVfT958656qAm4ZyLROQfiUjknPtkfq8x
            uQ0+iLDz3v9mHMfvVUpd4b1f9t6zsLBQLu43XxQvstYunCGOvoLK7Zd2tl20bsMLRz/hrrruF9TQ
            0ChRDCp4kX2bt21llUYQCL3pQ4SNeWLbyWLVZxDMg3hzr3hRI4nTkUFlNenVSr/3oljHYP+0wvYT
            ETqdTrter08uLS0dbDabMyLSAOpKqVngmIgcE5HjrLQgXk9V/RZR4WAbHR19/dVXX/1GEXmBTLXd
            BfzH8fHxTg6W+UNgSin1C3EcLwAnvPel6667brtzbtZ7/8sjIyOd3MlqROSKSqXyPiDKvd+B1vpr
            3vvdSql/EkXRzwC3e+8PkqnhFwEHn3rqqd/buXMnkM2nnTt3RtZa75zrq9pRFKGUitM0PT4Ag/2X
            wI/EcfzPReS4937BOTccRdEW4Plms/l7lUrWYFZrHQH2JFj3mSRJPmqM+SNjzB8CjI+P/zQQhmE4
            LSLnaa2rzWbzX2fCWDJ2zP7UA4B7zx23vwFROQwcgIB6NMRTX9+rufvtfxS95ub3pkMb0aaCcwpl
            VC5ZXX5Sk5W+8Z5q2mWit8jc1+5l/MhTTLjWGWur67ypnPFZTDs1Ps9NXxnj6cx0UauzhbzSeeMJ
            25foeWzVN5vNmYWFhUP1en0ySZJFY0zDObdIZosfy/+dVUrVlVJtEbHySsvMnGnN+k7XE77J49+x
            Y8fq0+We5oMHD6rrrrtuo4hsyK9yjAEHV+F0E5EwSZLNQLVUKtlOp3Oi1+s1Cwy4Uoo9e/YEu3bt
            2hJFUSW7hCAiGphxzi1679mzZ4++/vrrtzjnCoDMHLBUdJAtkkcOHDigLr744tqzzz7buuSSS3yt
            ViMIAqy1lU6n40ql0ip7vtvtxlrrrVrrWGvd6/V6x6vVai9Jkn4xlMXFxRLAhg0bumun21NPPaUu
            uuiisbGxsXqr1Up7vV48Ojq6udfrlYMg6DzxxBOTl19+uV/F6NBvx9D/dsdttyI6Q5uJ0ogytCSk
            WdvI80fmf6jyw+//X92RbahgKNtetNBRLlfZs4R6hWWsV2ds5nmOfflTnJssMmTbZ8HomfKRMbrv
            q+nqDMn5sqbK60ptbgt4XOqb8/PzR+fm5g53u90ZMjW8rpSaFZFj+QSaARaVUk0gGR0ddUVa4NLS
            0iubyeuMflo6GaPDisk1Ojq6anuR/FFI/jzc1TfLBrPDlFKrfhs87yAVaLZi/0Fqt9t9VbqoLTh4
            /aLufnG9tQ6/temnxTkGxzi47UxypQgDF3X4s8w4OctIZ7/vsgYVQXlsAz/yD+4LL33NdXpkI73c
            Oy/K5xq7QhMQKI1N62yXJu7Bv8IceIpR1yX2CaLOHH7OmkPkcW1VdKY89Y0OJgUUQIfiZekgotfr
            2XazPrO8vHxobm5ukqwxQhOYZ7X0niNj/A55q7izel7rtE7fIXR2WVfFNBcPdCHtLnLkwMf1eZdd
            14ubEJUzJxw631fjHXjXJTaCadfxCzMM2x6h+Myr/hJgJnmnypc0xBdB/1YcF6Rp2pg9duzI4uLi
            4V6vN6u1bpBBC2fImPt4/vcimRrYY72oxDp9F9ArS68UC88+/Um56oafr44Ob2hJ1s0UpfqJK1lz
            6IRhlbB88Dkqi0uUXQZOd+olBfLPitYWCOj1erbRaEzPz88fajabx5xzS2RSesF7fzLp3WVdeq/T
            dxm9Mkb3Huann0mOPPd5Mz72nmg0wiqLH4R9a08UWOLGDN3pw5R9ihGPRb4pEM8gCFBK0Ww2l+fm
            5o4sLi4eTtN0TkQaWutlYEYpdYzMez7LivROWJfe6/RdSq9Yor/xfT/sv/jYM38WXHbFe9JSBVUZ
            7fdxK7LBStKjPDNFZ2aSiBSvMvy4wiBnKTgHHROF46Go0JEkSTo3N3e8Xq8fWlpaOiYiS0BDKTUP
            THvvjymlpsls8brWuuu9d6xL73X6LqdXzOgbtIXnn7m/eeLmp6LRjVc7bxEdFbE6wBPbHumxgwx3
            64SSvAjYclaXzHPKC/vbe8/8/PxivV4/sri4eMQ5N0fG3EtKqRnvfRHzngUWRaQdhmESx7HXWrPe
            aWWdvhfoFavupaTNG9/7roUvTh78GBddfrV1Dgz9LLJypJG5RVrTh5mQDqEXlGQZakUz57OhorBA
            u93uzc7OHp+dnT3UbrePA8ta60YO+zuulDqutZ42xsw75xpAt1wu28HwyKnaFK/TOn230SusdebR
            PqFqBJ7d+wm57g3/hwpqY2LKEAihTxhOerSOHqTcblAxCvFpHl9/eW64hYWFhaWlpcPLy8tH0jSd
            B5rGmEXn3Iz3vvCcz4rIkve+BaSlUskXZXAH84pPVaxvndbpu41eGaMrOHT0CPXSEszPPZMcPHxf
            7dpt707wWO+JbJPh1iz1A3uooLBeYXSm0xsRDFlzxyL5HlYC/oPlczudTrK8vDy5uLh4sNFoTJN5
            xxtknvLjzrljwAky27tB5jl3xTm73S7d7nr3pJPS/+6Ane/1+3+J43+FNnqmooc2Zcctr3OHd3/j
            z/RFl77baw3lErXIkO59gWp7kcjbLM9cabT4bHyiYSAcVjB8GIZYa32j0ViYnZ091Ol0jna73QUy
            Jl5kddx7lgzw0iZLDfxOfzXrtE7fcnrFZYoNipKzbOwtcfjQ0/e3jr9udzQ0fKWTKirp0Zw6RC3p
            EGuHUYJI1vDQ5HXTvfeYYAUe6Jzrzs7OTs7Ozh7qdrvTQD1PKpkNw/B4mqbHyeLeC2Roth7rSSXr
            tE6npVfM6MoLxicMB8Jlr79h/tlDz3+suvPKK9O0h1+aQ5YWqYpF5+WklFZZLZYc0poXnpdmszm7
            tLR0aH5+flJEFoCm1nrBOXcCOK61Pp5njy2zLr3XaZ3Oil45oyuFkaxIRCQCz+//uL3m+M9s3Kwm
            Fg7spdKYJyZBAkPqUqKolENfhXar3Z5dnJ1st5uHGo3GCTLVvA7Maq2Pee+PK6VOAAsi0kzTtKe1
            XpWrd4qqIOu0Tus0QK9KhxGlsrLLkbNw7NDepUe+8u933HDjbywfP8CGUKNST+ocOjCIdX5+fn52
            dmb+ULPVnNQ6Y2Kl1IKInFBKHcvzc+eUUstKqQ7r0nud1ukV0Stm9KKYA8pScW2wy/D1z/3hk/sf
            iTaOjvyEioPtTmvdTbrt5eXlyYUTJw6lNp3xQoOAZe+YRThOng5aqO1KqWR8fNwtLi6uut4rTf9e
            p3X6XqRXjDa/8847gQwckzVSMHzx/i9V8ZxDYG7UyPVK6U1OXIL3bTwtYAmVp4NKv8BiXSnVFZF0
            06ZNUqjky8vLq663tsrGuur+Cul/9/DS9/r9v8Txf7Mqh2sUQ8B2hB3AFhRVIEFYAKZReTqo9D3n
            6xy7Tuv0TaJvZosATVbUbpism0lMZms38896MYd1WqdvEX0reoEoVlqfCHlw7dt94+u0Tt9L9P8D
            SEwpIkeeAxYAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMTEtMDRUMDQ6Mjc6NTUrMDA6MDAzgdXG
            AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTExLTA0VDA0OjI3OjU1KzAwOjAwQtxtegAAAABJRU5E
            rkJggg==" />
              </svg>
            </a>
          </h5>
        </div>
      </div>

    </div>
  </div>
</footer>