import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})

export class ShopComponent implements OnInit {

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService
  ) {  
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.spinner.hide('main_loader');
      }
    });
  }

  ngOnInit(): void {
  }
  
}
