import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BarRatingModule } from "ngx-bar-rating";
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { NgxSpinnerModule } from "ngx-spinner";

//Components
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';
import { SettingsComponent } from './components/settings/settings.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProductBoxComponent } from './components/product/product-box/product-box.component';
import { AwardMediaBoxComponent } from './components/award-media-box/award-media-box.component';
import { ContentWithImageComponent } from './components/content-with-image/content-with-image.component';
import { ServicesComponent } from './components/services/services.component';
import { CouponComponent } from '../checkout/widgets/coupon/coupon.component';

// Modals Components
import { NewsletterComponent } from './components/modal/newsletter/newsletter.component';
import { QuickViewComponent } from './components/modal/quick-view/quick-view.component';
import { CartModalComponent } from './components/modal/cart-modal/cart-modal.component';
import { VideoModalComponent } from './components/modal/video-modal/video-modal.component';
import { MiniCartComponent } from './components/modal/mini-cart/mini-cart.component';
import { ContentModalComponent } from './components/modal/content/content.component';

// Skeleton Loader Components
import { SkeletonProductBoxComponent } from './components/skeleton/product-box/product-box.component';
import { SkeletonAwardMediaBoxComponent } from './components/skeleton/awardmedia-box/awardmedia-box.component';

// Tap To Top
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';

// Pipes
import { DiscountPipe } from './pipes/discount.pipe';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { NewKeyValuePipe } from './pipes/newkeyvalue.pipe';
import { FormatPricePipe } from './pipes/format-price.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    SettingsComponent,
    BreadcrumbComponent,
    ProductBoxComponent,
    AwardMediaBoxComponent,
    ContentWithImageComponent,
    ServicesComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    VideoModalComponent,
    MiniCartComponent,
    ContentModalComponent,
    SkeletonProductBoxComponent,
    SkeletonAwardMediaBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    SafeHtmlPipe,
    NewKeyValuePipe,
    FormatPricePipe,
    CouponComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule.forRoot({
      // preset: scrollPreset // <-- tell LazyLoadImage that you want to use scrollPreset
    }),
    NgxSkeletonLoaderModule,
    TranslateModule,
    NgxPaginationModule,
    NgxGalleryModule,
    NgxSpinnerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    NgxGalleryModule,
    NgxSpinnerModule,
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    ProductBoxComponent,
    AwardMediaBoxComponent,
    ContentWithImageComponent,
    ServicesComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    VideoModalComponent,
    MiniCartComponent,
    ContentModalComponent,
    SkeletonProductBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    SafeHtmlPipe,
    NewKeyValuePipe,
    FormatPricePipe,
    CouponComponent
  ]
})
export class SharedModule { }
