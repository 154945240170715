<section class="content_with_img padding-bottom-70" [ngClass]="class" *ngIf="datacont.content != '' && datacont.image != ''">
    <div class="container">
        
        <div class="row" *ngIf="imageLeft">
            <div class="col-sm-12">
                <div class="sec-title" [innerHtml]="datacont.title | safeHtml"></div>
            </div>
            <div class="col-md-5">
                <img class="img-responsive" src="{{datacont.image}}" alt="" width="450" height="450" />
            </div>
            <div class="col-md-7">
                <div class="sec-content" [innerHtml]="datacont.content | safeHtml"></div>
                <div class="see-more" *ngIf="seeMore"><a [routerLink]="['info/about-us']"><span class="seeMoreText">See More</span></a></div>
            </div>
        </div>
        <div class="row" *ngIf="!imageLeft">
              <div class="col-12">  <div class="sec-title" [innerHtml]="datacont.title | safeHtml"></div></div>
            <div class="col-md-7">
                <div class="sec-content" [innerHtml]="datacont.content | safeHtml"></div>
                <div class="see-more" *ngIf="seeMore"><a [routerLink]="['info/about-us']"><span class="seeMoreText">See More</span></a></div>
            </div>
            <div class="col-md-5">
                <img class="img-responsive" src="{{datacont.image}}" alt="" width="450" height="450" />
            </div>
        </div>
    </div>
    
</section>