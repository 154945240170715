import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Product } from '../classes/product';
import { BaseService } from './base.service';

const cart = {
	wishlist: [],
	items: [],
	totals: []
}

@Injectable({
	providedIn: 'root'
})

export class CartService extends BaseService {
	public miniCartModal: boolean = false;

	constructor(
		protected http: HttpClient
	) {
		super(http);
		this.updateCartState();
		this.updateWishlistState();
	}

	public getCart() {
		return cart;
	}

	public items(id: any = 0) {
		if (id) {
			return cart.items.find((item, index) => {
	      		if (parseInt(item.cart_item_id) === parseInt(id)) {
	        		return item;
	      		}
	    	});
    	} else {
    		return cart.items;
    	}
	}

	public totals(code = '') {
		if (code) {
			return cart.totals.find((total, index) => {
	      		if (total.code === code) {
	        		return total;
	      		}
	    	});
    	} else {
    		return cart.totals;
    	}
	}

	public subTotal() {
		let result = this.totals('total');
		if (result) {
			return result.text;
		}

		return '0';
	}

	public totalQty(): number {
		let qty = 0;
		let items = this.items();
		if (items.length > 0) {
			for (let item of items) {
				qty += parseInt(item.quantity);
			}
		}

		return qty;
	}

	public async updateCartState() {
		let resp = await this.getItems().toPromise();
		cart.items = resp.products;
		cart.totals = resp.totals;
	}

	public async updateStateTotals() {
		let resp = await this.getTotals().toPromise();
		cart.totals = resp;
	}

	public getItems(): Observable<any> {
		return this.getApi('checkout/cart');
	}

	public getTotals(): Observable<any> {
		return this.getApi('checkout/cart/totals');
	}

	public addToCart(post_data): Observable<any> {
		return this.postApi('checkout/cart/add', post_data);
	}

	public updateCart(cart_item_id, quantity): Observable<any> {
		const post_data = new FormData();
	    post_data.append("key", cart_item_id);
	    post_data.append("quantity", quantity);
		return this.postApi('checkout/cart/edit', post_data);
	}

	public removeCartItem(cart_item_id): Observable<any> {
		const post_data = new FormData();
	    post_data.append("key", cart_item_id);
		return this.postApi('checkout/cart/remove', post_data);
	}

	public couponApply(code:string): Observable<any> {
		const post_data = new FormData();
	    post_data.append("coupon", code);
		return this.postApi('checkout/coupon', post_data);
	}

	public removeCoupon(): Observable<any> {
		const post_data = new FormData();
		return this.postApi('checkout/coupon/remove', post_data);
	}

	/*---------------  Wish List  -----------------*/

	public isAddedToWishlist(pid: any = 0) {
		return cart.wishlist.find((item, index) => {
      		if (parseInt(item.product_id) === parseInt(pid)) {
        		return true;
      		}
    	});

    	return false;
	}

	public wishlistItems(pid: any = 0) {
		if (pid) {
			return cart.wishlist.find((item, index) => {
	      		if (parseInt(item.product_id) === parseInt(pid)) {
	        		return item;
	      		}
	    	});
	    	return [];
    	} else {
    		return cart.wishlist;
    	}
	}

	public wishlistCount(): number {
		return cart.wishlist.length;
	}

	public async updateWishlistState() {
		let resp = await this.getWishlist().toPromise();
		cart.wishlist = resp.products;
	}

	public getWishlist(): Observable<any> {
		return this.getApi('account/wishlist');
	}

	public addToWishlist(product_id): Observable<any> {
		const post_data = new FormData();
	    post_data.append("product_id", product_id.toString());
	    return this.postApi('account/wishlist/add', post_data);
	}

	public removeFromWishlist(product_id): Observable<any> {
		const post_data = new FormData();
	    post_data.append("product_id", product_id.toString());
	    return this.postApi('account/wishlist/remove', post_data);
	}
}
