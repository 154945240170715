import { Injectable, EventEmitter, Output, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const ApiUrl = environment.api_url;

@Injectable({
	providedIn: 'root'
})

export class BaseService implements OnInit {

    constructor(
		protected http: HttpClient
	) {}

    public getApi(url: string): Observable<any> {
        let nurl = ApiUrl + url;
        return this.http.get(nurl).pipe(
        	map((response: Response) => response),
            catchError(this.handleError)
        );
    }

    public postApi(url: string, post_data: any): Observable<any> {
        let nurl = ApiUrl + url;
        return this.http.post(nurl, post_data).pipe(
        	map((response: Response) => response),
            catchError(this.handleError)
        );
    }

    private handleError(error: Response) {
        if (error.status === 504 || error.status === 502 || error.status === 503) {
            return of('');
        } else {
            return of(JSON.stringify(error) || 'Server error');
        }
    }

    ngOnInit() {}
}