import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { AuthService } from "../../shared/services/auth.service";
import { LocalisationService } from "../../shared/services/localisation.service";
import { SearchService } from "../../shared/services/search.service";
import { CartService } from "../../shared/services/cart.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  showPromo: boolean = true;
  searchForm: FormGroup;
  private searchTimeout: any = null;
  private routePath: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private fb: FormBuilder,
    private localeService: LocalisationService,
    private searchService: SearchService,
    private cartService: CartService,
    private authService: AuthService         
  ) {
    this.route.url.subscribe((url: UrlSegment[])=> {
      if (url.length > 0)
        this.routePath = url[0].path
    });
    this.authService.checkLoggedIn();
    let searchStr = '';
    this.route.queryParams.subscribe(params => {
        searchStr = params.search ? params.search : '';
        this.searchService.searchTerm.next(searchStr);
    });
    this.searchForm = fb.group({
      search: [searchStr],
    });
  }

  ngOnInit(): void {
  }

  get config() {
    return this.localeService.config;
  }

  get isLogedIn() {
    return this.authService.isLogged;
  }

  hidePromo() {
    this.showPromo = false;
  }

  search() {
    let stext = this.searchForm.value.search;
    this.searchService.searchTerm.next(stext);
    if (this.routePath == 'search') {
      let url = this.router.createUrlTree(['/search'], {
        queryParams: { 
          search: stext ? stext : null
        }
      });
      this.location.replaceState(this.router.serializeUrl(url));
    } else {
      this.router.navigate(["/search"], {
        queryParams: {
          search: stext ? stext : null
        }
      });
    }
  }

  onChangeSearch(e) {
    clearTimeout(this.searchTimeout);
    var $this = this;
    let stext = e.target.value;
    this.searchTimeout = setTimeout(function () {
      if (e.keyCode != 13) {
        $this.searchService.searchTerm.next(stext);
        if ($this.routePath == 'search') {
          let url = $this.router.createUrlTree(['/search'], {
            queryParams: { 
              search: stext ? stext : null
            }
          });
          $this.location.replaceState($this.router.serializeUrl(url));
        } else {
          $this.router.navigate(["/search"], {
            queryParams: {
              search: stext ? stext : null
            }
          });
        }
      }
    }, 500);    
  }

  get cartQty() {
    return this.cartService.totalQty();
  }

  showCart(event: Event) {
    this.cartService.miniCartModal = true;
    event.preventDefault();
    event.stopPropagation();
  }
}
