import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AccountComponent } from './account/account.component';
import { PagesComponent } from './pages/pages.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { BlogComponent } from './blog/blog.component';
import { ProductComponent } from './product/product-component';
import { SearchComponent } from './search/search.component';
import { ShopComponent } from './shop/shop.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'account',
        component: AccountComponent,
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
    },
    {
        path: 'info',
        component: PagesComponent,
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'checkout',
        component: CheckoutComponent,
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
    },
    {
        path: 'blog',
        component: BlogComponent,
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
    },
    {
        path: 'search',
        component: SearchComponent,
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
    },
    {
        path: 'shop',
        component: ShopComponent,
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
    },
    {
        path: '**', //if not found any page 
        redirectTo: 'info/not-found',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        useHash: false,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})

export class AppRoutingModule {}
