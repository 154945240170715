<ng-template #contentModal let-modal>
    <div class="content-container" *ngIf="type=='cont'">
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    	<div class="modal-header">
    		<h5 class="modal-title" [innerHtml]="title | safeHtml"></h5>
    	</div>
        <div class="modal-body">
            <div class="content" [innerHtml]="content | safeHtml"></div>
        </div>
    </div>
    <div class="imgprev-container" *ngIf="type=='img'">
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
            <div class="content">
                <img [src]="imgsrc | safeHtml:'resourceUrl'" [alt]="imgalt | safeHtml" />
            </div>
        </div>
    </div>
</ng-template>