import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER  } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Location } from '@angular/common'; 
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountComponent } from './account/account.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HomeComponent } from './home/home.component';
import { CollectionComponent } from './collection/collection.component';
import { ProductComponent } from './product/product-component';
import { SearchComponent } from './search/search.component';
import { ShopComponent } from './shop/shop.component';

import { PagesComponent } from './pages/pages.component';
import { BlogComponent } from './blog/blog.component';

import { AuthService } from './shared/services/auth.service';
import { SettingsService } from './shared/services/settings.service';
import { LocalisationService } from './shared/services/localisation.service';
import { HttpConfigInterceptor } from './shared/interceptor/http-config';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function initApp(settingsService: SettingsService) {
    return (): Promise < any > => {
        return settingsService.getApiToken();
    }
}

export function checkUserLogged(authService: AuthService) {
    return (): Promise < any > => {
        return authService.checkLogged();
    }
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        AccountComponent,
        CollectionComponent,
        PagesComponent,
        BlogComponent,
        CheckoutComponent,
        ProductComponent,
        SearchComponent,
        ShopComponent
    ],
    imports: [
        BrowserModule.withServerTransition({
            appId: 'serverApp'
        }),
        BrowserAnimationsModule,
        HttpClientModule,
        NgbModule,
        //LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            extendedTimeOut: 300,
            progressBar: false,
            enableHtml: true,
            positionClass: 'toast-bottom-center'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        AppRoutingModule
    ],
    providers: [
        SettingsService,
        LocalisationService,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            multi: true,
            deps: [SettingsService]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: checkUserLogged,
            multi: true,
            deps: [AuthService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

/*const __stripTrailingSlash = (Location as any).stripTrailingSlash;
Location.stripTrailingSlash = function(url) {
    if (url.endsWith('/')) {
        url = url;
    } else {
        url = url + '/';
    }
    const queryString$ = url.match(/([^?]*)?(.*)/);
    if (queryString$[2].length > 0) {
        return /[^\/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);
    }

    return /[^\/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);
};*/
