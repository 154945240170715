<div class="am-box" *ngIf="!loader && awardmedia.link">
    <div class="img-wrapper">
        <a href="{{ awardmedia.link }}" target="_blank" *ngIf="awardmedia.link_type == 'link'">
            <img class="img-fluid" [src]="awardmedia.thumb" [alt]="awardmedia.title" width="150" height="180" />
        </a>
        <img class="img-fluid cursorp" [src]="awardmedia.thumb" [alt]="awardmedia.title" width="150" height="180" *ngIf="awardmedia.link_type == 'ytvideo'" (click)="VideoModal.openModal()" />
    </div>
    <!--<div class="am-detail">
        <a href="{{ awardmedia.link }}" target="_blank" *ngIf="awardmedia.link_type == 'link'">
            <h6 class="title">{{ awardmedia.title | titlecase }}</h6>
        </a>
        <h6 class="title cursorp" *ngIf="awardmedia.link_type == 'ytvideo'" (click)="VideoModal.openModal()">{{ awardmedia.title | titlecase }}</h6>
    </div>-->
</div>
<div class="am-box" *ngIf="!loader && !awardmedia.link">
    <div class="img-wrapper cursor-pointer" (click)="ImgprevModal.openModal()">
        <img class="img-fluid" [src]="awardmedia.thumb" [alt]="awardmedia.title" width="150" height="180" />
    </div>
    <!--<div class="am-detail cursor-pointer" (click)="ImgprevModal.openModal()">
        <h6 class="title">{{ awardmedia.title | titlecase }}</h6>
    </div>-->
</div>
<app-skeleton-awardmedia-box *ngIf="loader"></app-skeleton-awardmedia-box>
<app-video-modal #videoModal *ngIf="awardmedia.link_type == 'ytvideo'" [link]="awardmedia.link"></app-video-modal>
<app-content-modal *ngIf="!awardmedia.link" #imgprevModal [type]="'img'" [imgsrc]="awardmedia.popup" [imgalt]="awardmedia.title"></app-content-modal>