import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { map, filter, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";

import { LocalisationService } from './shared/services/localisation.service';
import { AuthService } from "./shared/services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private spinner: NgxSpinnerService,
    private localeService: LocalisationService,
    private authService: AuthService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
  }

  ngOnInit() {
    this.spinner.show('main_loader');
    //setTimeout(()=>{ this.spinner.hide('main_loader'); }, 5000);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {
        var rt = this.getChild(this.activatedRoute)
 
        rt.data.subscribe(data => {
          let metadata = data.data;
          let title = this.localeService.config.meta_title;
          if (metadata && metadata.meta_title) {
            this.titleService.setTitle(metadata.meta_title + ' - ' + title)
          } else if (data.title) {
            this.titleService.setTitle(data.title + ' - ' + title)
          } else {
            this.titleService.setTitle(title);
          }
 
          if (metadata && metadata.meta_description) {
            this.metaService.updateTag({ name: 'description', content: metadata.meta_description })
          } else if (data.description) {
            this.metaService.updateTag({ name: 'description', content: data.description })
          } else {
            this.metaService.updateTag({name: 'description', content: this.localeService.config.meta_description})
          }

          if (metadata && metadata.meta_keyword) {
            this.metaService.updateTag({name: 'keywords', content: metadata.meta_keyword})
          } else if (data.keyword) {
            this.metaService.updateTag({ name: 'keywords', content: data.keyword })
          } else {
            this.metaService.updateTag({name: 'keywords', content: this.localeService.config.meta_keyword})
          }
 
          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }
 
          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }
 
          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }
 
          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
 
          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }
 
        })
 
      })
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

}
