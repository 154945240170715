import { Component, OnInit, OnDestroy, ViewChild, TemplateRef,
  Injectable, PLATFORM_ID, Inject, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentModalComponent implements OnInit, OnDestroy {
  @ViewChild("contentModal", { static: false }) ContentModal: TemplateRef<any>;
  @Input() title: any = '';
  @Input() content: any = '';
  @Input() type: string = 'cont';
  @Input() imgsrc: string = '';
  @Input() imgalt: string = '';

  private closeResult: string;
  private modalOpen: boolean = false;
  private class: string = 'content-modal';
  private ariaLabel: string = 'Content-Modal';

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    if (this.type == 'img') {
      this.ariaLabel = 'Image-Preview-Modal';
      this.class = 'imgprev-modal';
    }
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.ContentModal, { 
        size: 'lg',
        ariaLabelledBy: this.ariaLabel,
        centered: true,
        windowClass: 'modal fade ' + this.class
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
