import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Product } from '../classes/product';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})

export class ProductService extends BaseService  {

	// Get Products By Slug
	public getProductBySlug(slug: string): Observable < Product > {
		return this.getApi('product/product&product_slug=' + slug);
	}

	// Get Product By ID
	public getProduct(id): Observable < Product > {
		return this.getApi('product/product&product_id=' + id);
	}

	public getRelatedProduct(id, limit = 20): Observable < Product[] > {
		return this.getApi('product/product/related&product_id=' + id + '&limit=' + limit);
	}

	public getRecentViewProduct(id, limit = 20): Observable < Product[] > {
		return this.getApi('product/product/recent_view&product_id=' + id + '&limit=' + limit);
	}

	public getPopularProduct(): Observable < Product[] > {
		return this.getApi('product/product/popular');
	}

	public getReviews(id, page): any {
		return this.getApi('product/product/review&product_id=' + id + '&page=' + page);
	}

	public getAllReviews(id, page, limit): any {
		return this.getApi('product/product/allreviews&product_id=' + id + '&page=' + page + '&limit=' + limit);
	}

	public saveReview(post_data): any {
		return this.postApi('product/product/write_review', post_data);
	}
}
