<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="quick-view-img">
                        <owl-carousel-o [options]="owlMainSliderConfig" #owlCar class="product-slick">
                            <ng-container *ngFor="let image of product.images; index as i">
                                <ng-template carouselSlide [id]="i" [width]="350">
                                    <div class="owl-thumb">
                                        <img [defaultImage]="'assets/images/product/placeholder.jpg'" 
                                        [lazyLoad]="image" 
                                        [alt]="product.name" class="img-fluid" [src]="image">
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                    <div class="img-thumb" *ngIf="product.images.length > 1" style="width:350px;">
                        <owl-carousel-o [options]="owlThumbConfig" class="product-slick">
                            <ng-container *ngFor="let image of product.images; index as i">
                                <ng-template carouselSlide [id]="i" [width]="80">
                                    <div class="owl-thumb" [class.active]="i == activeSlide">
                                        <img [defaultImage]="'assets/images/product/placeholder.jpg'" 
                                        [lazyLoad]="image" 
                                        [alt]="product.name" class="img-fluid" [src]="image" (click)="owlCar.to(activeSlide = i.toString())" />
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="product-right">
                        <h2><a [routerLink]="['/shop/', product.handle]">{{ product.name | titlecase }}</a></h2>
                        <h3>{{ product.price }}
                            <del *ngIf="product.special"><span class="money">{{ product.special }}</span></del>
                        </h3>
                        <div class="p-description mb-2" *ngIf="product.description">
                           
                            <p>{{ product.description }}</p>
                        </div>
                        <div class="product-description">                            
                            <h5 class="avalibility" *ngIf="counter <= product.stock"><span>In Stock</span></h5>
                            <h5 class="avalibility" *ngIf="counter > product.stock"><span>Out of Stock</span></h5>
                        </div>
                        <form method="post" class="add-to-cart" novalidate>
                            <div class="row">
                                <div class="col-sm-12 cart-options" *ngIf="product.options">
                                    <div class="form-group" *ngFor="let option of product.options; index as i">
                                        <label for="option-{{option.product_option_id}}">
                                            <ng-container *ngIf="option.name == 'Weight'">
                                                Select 
                                            </ng-container>{{option.name}}
                                            <span class="required" *ngIf="option.required">*</span>
                                        </label>
                                        <select [formControl]="optionControls[option.product_option_id]" id="option-{{option.product_option_id}}" class="form-control" *ngIf="option.type=='select'" >
                                            <option value="">Please Select</option>
                                            <option *ngFor="let optval of option.product_option_value;" value="{{optval.product_option_value_id}}" [selected]="optval.product_option_value_id == option.value">{{optval.name}}</option>
                                        </select>
                                        <input type="text" id="option-{{option.product_option_id}}" class="form-control cus-msg" *ngIf="option.type=='text'" [formControl]="optionControls[option.product_option_id]">
                                        <div *ngIf="isFieldValid('prodoption.'+option.product_option_id)" class="text text-danger">{{option.name}} is required.</div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="quantity">Quantity</label>
                                        <div class="qty-box">
                                            <div class="input-group">
                                                <span class="input-group-prepend">
                                                <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                                <i class="ti-angle-left"></i>
                                                </button>
                                                </span>
                                                <input type="text" class="form-control input-number" [formControl]="addToCartForm.controls['quantity']" />
                                                <span class="input-group-prepend">
                                                <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                                <i class="ti-angle-right"></i>
                                                </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group cart-btn">
                                        <input type="hidden" [formControl]="addToCartForm.controls['product_id']" />
                                        <button type="button" class="btn btn-solid add-to-cart" [class.disabled]="isDisabledAtc" (click)="addToCart()">Add to Cart</button>
                                        <button type="button" class="btn btn-solid buy-now" [class.disabled]="isDisabledAtc" (click)="buyNow()">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>