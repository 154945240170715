<div class="product-box" *ngIf="!loader">

    <!-- <span class="sale-tag"> Sale </span> -->
    <div class="img-wrapper">
        <div class="front">
            <a [routerLink]="['/shop/', product.handle]">
                <img class="img-fluid" [src]="product.thumb" [alt]="product.name" width="160" height="180" />
            </a>
        </div>
    </div>
    <div class="cart-info cart-wrap">
        <a href="javascript:void(0)" title="Remove from Wishlist" (click)="removeFromWishlist()"
            *ngIf="isAddedToWishlist" [class.disabled]="submitted">
            <i class="fa fa-heart" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist()" *ngIf="!isAddedToWishlist"
            [class.disabled]="submitted">
            <i class="fa fa-heart-o" aria-hidden="true"></i>
        </a>
    </div>
    <div class="product-detail pl-4 mb-4 pr-4 pt-0">
        <a [routerLink]="['/shop/', product.handle]">
            <h6 class="m-0">
                {{ product.name | titlecase }} <span class="p-attr"><ng-container *ngIf="doptnames">| {{ doptnames }}
                    </ng-container></span>
            </h6>
        </a>

        <!-- <div class="short-desc" [innerHtml]="product.description | safeHtml"></div> -->
        <div class="price-wrapper pppp">
            <ng-container *ngIf="newsprice > 0; then spPrice else normPrice"></ng-container>
            <ng-template #spPrice>
                <span class="price">{{ newsprice | formatPrice:currency }}/- </span>
                <!-- <del><span class="old-price">{{ newprice | formatPrice:currency }}/-</span></del> -->
            </ng-template>
            <ng-template #normPrice><span class="price">{{ newprice | formatPrice:currency }}/-</span></ng-template>
        </div>
        <bar-rating [rate]="product.rating" [readOnly]="true" *ngIf="config.review_status"></bar-rating>

        <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()" *ngIf="config.quickview_status">
            <i class="ti-eye" aria-hidden="true"></i>
        </a>
    </div>
    <!-- <div class="add-wrap">
        <div class="cart-buttons">
            <button type="button" class="btn btn-solid add-to-cart" disabled>
                Add to Cart (Coming Soon)
            </button>
        </div>
    </div> -->
    <div class="add-wrap">
        <div class="number">
            <span class="minus" (click)="decrement()">-</span>
            <!-- <span class="qty">{{ quantity }}</span> -->
            <span class="qty">1</span>
            <span class="plus" (click)="increment()">+</span>
        </div>
        <div class="cart-buttons">
            <button type="button" class="btn btn-solid add-to-cart" disabled>
                Add to Cart (Coming Soon)
            </button>
        </div>
        <div class="cart-buttons hide-now">
            <button type="button" class="btn btn-solid add-to-cart" (click)="CartModal.openModal()" *ngIf="cartModal"
                [disabled]="submitted">Add to Cart <span><img src="assets/images/arrow-black.png"></span> </button>
            <button type="button" class="btn btn-solid add-to-cart" (click)="addToCart()" *ngIf="!cartModal"
                [disabled]="submitted"> Add to Cart <span><img src="assets/images/arrow-black.png"></span></button>
        </div>
    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" *ngIf="config.quickview_status"></app-quick-view>
<app-cart-modal #cartModal [product]="product" *ngIf="cartModal"></app-cart-modal>