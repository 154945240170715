import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})

export class SearchService extends BaseService  {
	public searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	public searchProduct(post_data): Observable<any> {
		return this.postApi('product/search', post_data);
	}
}
