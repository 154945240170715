import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from "../../../services/product.service";
import { CartService } from "../../../services/cart.service";
import { Product } from "../../../classes/product";

@Component({
    selector: 'app-cart-modal',
    templateUrl: './cart-modal.component.html',
    styleUrls: ['./cart-modal.component.scss']
})
export class CartModalComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() product: Product;

    @ViewChild("cartModal", { static: false }) CartModal: TemplateRef<any>;

    public closeResult: string;
    public modalOpen: boolean = false;
    public products: Product[] = [];

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
        private modalService: NgbModal,
        private router: Router,
        private productService: ProductService,
        private cartService: CartService
    ) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }

    async openModal() {
        let product_id = this.product.product_id.toString();
        let post_data = new FormData();
        post_data.append('product_id', product_id);
        let status = 0;
        let atcresp = await this.cartService.addToCart(post_data).toPromise();
        if (atcresp.error && atcresp.error.option) {
            this.router.navigateByUrl('/shop/'+this.product.handle);
        }

        if (atcresp.status) {
            this.cartService.updateCartState();
            status = 1;
        }

        if (status) {
            //await this.productService.getRelatedProduct(product_id, 2).subscribe(response => {
                //this.products = response;
           // });
            this.modalOpen = true;
            if (isPlatformBrowser(this.platformId)) { // For SSR 
                this.modalService.open(this.CartModal, { 
                    size: 'lg',
                    ariaLabelledBy: 'Cart-Modal',
                    centered: true,
                    windowClass: 'theme-modal cart-modal CartModal'
                }).result.then((result) => {
                    `Result ${result}`
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            }
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnDestroy() {
        if(this.modalOpen){
            this.modalService.dismissAll();
        }
    }

}
